export default [{
    code: 'ucf1',
    colourName: 'Stengrå',
    type: '3D fabric',
    colour: 'Stengra',
    active: true
  },
  {
    code: 'ucf2',
    colourName: 'Limegrøn',
    type: '3D fabric',
    colour: 'Limegron',
    active: false
  },
  {
    code: 'ucf3',
    colourName: 'Mosgrøn',
    type: '3D fabric',
    colour: 'Mosgron',
    active: false
  },
  {
    code: 'ucf4',
    colourName: 'Dueblå',
    type: '3D fabric',
    colour: 'Duebla',
    active: false
  },
  {
    code: 'ucf5',
    colourName: 'Marineblå',
    type: '3D fabric',
    colour: 'Bla',
    active: false
  },
  {
    code: 'ucf6',
    colourName: 'Støvet Lilla',
    type: '3D fabric',
    colour: 'Stovet-Lilla',
    active: false
  },
  {
    code: 'ucf7',
    colourName: 'Brændt Orange',
    type: '3D fabric',
    colour: 'Braendt-Orange',
    active: false
  },
  {
    code: 'ucf8',
    colourName: 'Rød',
    type: '3D fabric',
    colour: 'Rod',
    active: false
  },
  {
    code: 'ucl1',
    colourName: 'Mørkegrå',
    type: 'Leather',
    colour: 'Morkegra',
    active: false
  },
  {
    code: 'ucl2',
    colourName: 'Frisk Grøn',
    type: 'Leather',
    colour: 'Frisk-Gron',
    active: false
  },
  {
    code: 'ucl3',
    colourName: 'Lys Blå',
    type: 'Leather',
    colour: 'Lysbla',
    active: false
  },
  {
    code: 'ucl4',
    colourName: 'Marineblå',
    type: 'Leather',
    colour: 'Marinebla',
    active: false
  },
  {
    code: 'ucl5',
    colourName: 'Lilla',
    type: 'Leather',
    colour: 'Lilla',
    active: false
  },
  {
    code: 'ucl6',
    colourName: 'Gul',
    type: 'Leather',
    colour: 'Gul',
    active: false
  },
  {
    code: 'ucl7',
    colourName: 'Orange',
    type: 'Leather',
    colour: 'Orange',
    active: false
  },
  {
    code: 'ucl8',
    colourName: 'Rød',
    type: 'Leather',
    colour: 'Rod',
    active: false
  }
]
