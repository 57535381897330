export default [
  {
      id: 'COA',
      option: 'A',
      optionName: 'Option A',
      active: true,
      thumbnail: '/img/exterior/carbon/tiles/A.jpg',
    },
    {
      id: 'COB',
      option: 'B',
      optionName: 'Option B',
      active: false,
      thumbnail: '/img/exterior/carbon/tiles/B.jpg',
    }
]
