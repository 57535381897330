<template>
  <transition name="fade">
    <div id="exteriorWheel" class="bg" v-if="$store.state.views[8].show">
      <div id="base-w" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.baseW + ')' }"></div>
      <div id="bodyColour-w" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.bodyColourW + ')' }"></div>
      <div id="brakeDisc-w" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.brakeDiscW + ')' }"></div>
      <div id="brakeCaliper-w" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.brakeCaliperW + ')' }"></div>
      <div id="wheel-w" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.wheelW + ')' }"></div>
      <div id="visibleCarbon-w" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.visibleCarbonW + ')' }" alt="A"></div>
      <div id="accents-w" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.accentsW + ')' }"></div>
      <!-- <div id="accentsWheel-w" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.accentsWheelsW + ')' }"></div> -->
    </div>
    </transition>
</template>

<script>
  export default {

  };
</script>
