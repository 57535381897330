<template>
  <main>
    <router-view></router-view>
  </main>
</template>

<script>
export default {};
</script>

<style>
@import './assets/styles/styles.css';
@import './assets/styles/styles-mobile.css';
/* @import './assets/styles/account.css'; */
</style>
