<template>
  <div id="exteriorRear" class="bg" v-if="$store.state.views[4].show">
    <div id="base-r" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.baseR + ')' }"></div>
    <div id="bodyColour-r" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.bodyColourR + ')' }"></div>
    <div id="visibleCarbon-r" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.visibleCarbonR + ')' }"></div>
    <div id="accents-r" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.accentsR + ')' }"></div>
    <div id="logo-r" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.logoR + ')' }"></div>
  </div>
</template>

<script>
  export default {
  };
</script>
