<template>
  <div id="viewMobile" >

  </div>
</template>

<script>
export default {
};
</script>
