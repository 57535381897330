export default [
  {
    id: 'viewFront34',
    name: 'Front 3/4',
    show: true,
    class: 'dropdown-item-1',
    href: '#tab-content-exterior'
  },
  {
    id: 'viewFront',
    name: 'Front',
    show: false,
    class: 'dropdown-item-2',
    href: '#tab-content-exterior'
  },
  {
    id: 'viewSide',
    name: 'Side',
    show: false,
    class: 'dropdown-item-3',
    href: '#tab-content-exterior'
  },
  {
    id: 'viewRear34',
    name: 'Rear 3/4',
    show: false,
    class: 'dropdown-item-4',
    href: '#tab-content-exterior'
  },
  {
    id: 'viewRear',
    name: 'Rear',
    show: false,
    class: 'dropdown-item-5',
    href: '#tab-content-exterior'
  },
  {
    id: 'viewTop',
    name: 'Top',
    show: false,
    class: 'dropdown-item-6',
    href: '#tab-content-exterior'
  },
  {
    id: 'viewLogoF',
    name: 'Front logo',
    show: false,
    class: 'dropdown-item-7',
    href: '#tab-content-exterior'
  },
  {
    id: 'viewLogoR',
    name: 'Rear logo',
    show: false,
    class: 'dropdown-item-8',
    href: '#tab-content-exterior'
  },
  {
    id: 'viewWheel',
    name: 'Wheel',
    show: false,
    class: 'dropdown-item-9',
    href: '#tab-content-exterior'
  },
  {
    id: 'viewInterior1',
    name: 'Driver',
    show: false,
    class: 'dropdown-item-10',
    href: '#tab-content-interior'
  },
  {
    id: 'viewInterior2',
    name: 'Windshield',
    show: false,
    class: 'dropdown-item-11',
    href: '#tab-content-interior'
  },
  {
    id: 'viewInterior3',
    name: 'Steering wheel',
    show: false,
    class: 'dropdown-item-12',
    href: '#tab-content-interior'
  },
]
