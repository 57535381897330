export default [{
    id: 'AT1',
    type: 'A',
    typeName: 'Anodized',
    active: true,
  },
  {
    id: 'AT2',
    type: 'P',
    typeName: 'Precious metals',
    active: false,
  }
]
