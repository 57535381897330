<template>
<div class="tab-pane fade row" id="tab-content-eco" role="tabpanel">

  <div id="exteriorCarbonOption" class="row__inner">

    <div class="tile" @click="outputCarbonOption(id)" :key="id" v-for="(item, id) in $store.state.exteriorCarbonOptions">
      <div class="tile__media">
        <img class="tile__img" :class="{ tile__up: item.active }" :src=item.thumbnail alt="" />
      </div>
      <div class="tile__details" :class="{ tile__active: item.active }">
        <div class="tile__title">
          {{ item.optionName }}
        </div>
      </div>
    </div>

  </div>

</div>
</template>

<script>
export default {
  computed: {
    exteriorCarbonOptions() {
      return this.$store.getters.exteriorCarbonOptions
    }
  },
  methods: {
    outputCarbonOption(id) {
      const storeSum = this.$store.state.exteriorSummary;
      const self = this.$store.state.exteriorCarbonOptions[id];
      storeSum.cOption = self.option;

      if (this.$store.state.exteriorCarbonPlaces[1].active === true && self.option === 'A') {
        this.$store.state.exteriorCarbonPlaces[1].active = false;
        this.$store.state.exteriorCarbonPlaces[2].active = true;
        storeSum.cPlace = this.$store.state.exteriorCarbonPlaces[2].place;
        this.$store.dispatch('changeCarbon');
      } else {
        this.$store.dispatch('changeCarbon');
      }

      this.$store.state.exteriorCarbonOptions.forEach(function(item) {
        item.active = false;
      })
      self.active = !self.active;

      this.$store.state.exteriorSummarySpecs[1].content = self.optionName;
      return;
    }
  }
}
</script>
