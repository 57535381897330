<template>
<div class="tab-pane fade row" id="tab-content-aluminum" role="tabpanel">

  <ul id="aluminumRow" class="nav sub-options" role="tablist">
    <li class="nav-item" :key="id" v-for="(item, id) in $store.state.interiorAluminumTypes" @click="outputAluminumType(id)" role="presentation">
      <a class="nav-link" :class="{ active: item.active }" data-toggle="pill" :href="'#aluminum' + item.type" role="tab">{{ item.typeName }}</a>
    </li>
  </ul>

  <div class="tab-content" id="aluminum-tabContent">

    <div id="aluminumA" class="tab-pane fade row__inner show active" role="tabpanel">

      <div class="tile" @click="outputAluminum(id)" :key="id" v-for="(item, id) in anodized">
        <div class="tile__media">
          <img class="tile__img" :class="{ tile__up: item.active }" :src="'/img/interior/aluminum/thumbnails/' + item.type + item.al + '.jpg'" alt="" />
        </div>
        <div class="tile__details" :class="{ tile__active: item.active }">
          <div class="tile__title">
            {{ item.alName }}
          </div>
        </div>
      </div>

    </div>

    <div id="aluminumP" class="tab-pane fade row__inner" role="tabpanel">

      <div class="tile" @click="outputAluminum(id)" :key="id" v-for="(item, id) in precious">
        <div class="tile__media">
          <img class="tile__img" :class="{ tile__up: item.active }" :src="'/img/interior/aluminum/thumbnails/' + item.type + item.al + '.jpg'" alt="" />
        </div>
        <div class="tile__details" :class="{ tile__active: item.active }">
          <div class="tile__title">
            {{ item.alName }}
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
</template>

<script>
export default {
  computed: {
    anodized: function() {
      return this.$store.state.interiorAluminum.filter(i => i.type === 'A')
    },
    precious: function() {
      return this.$store.state.interiorAluminum.filter(i => i.type === 'P')
    }
  },
  methods: {
    outputAluminumType(id) {
      const storeSum = this.$store.state.interiorSummary;
      const self = this.$store.state.interiorAluminumTypes[id];
      storeSum.alType = self.typeName;
      return;
    },
    outputAluminum(id) {
      const storeSum = this.$store.state.interiorSummary;
      var self
      if (storeSum.alType === 'Anodized') {
        self = this.$store.state.interiorAluminum[id];
      } else {
        self = this.$store.state.interiorAluminum[id + 5];
      }

      storeSum.alColour = self.al;

      this.$store.dispatch('changeAluminum');

      self.active = !self.active;

      this.$store.state.interiorSummarySpecs[6].content = storeSum.alType + ', ' + self.alName;

      return;
    },
  }
}
</script>
