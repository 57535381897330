<template>
<div class="tab-pane fade row" id="tab-content-ect" role="tabpanel">

  <div id="exteriorCarbonType" class="row__inner">

    <div class="tile" @click="outputCarbonType(id)" :key="id" v-for="(item, id) in $store.state.exteriorCarbonType">
      <div class="tile__media">
        <img class="tile__img" :class="{ tile__up: item.active }" :src=item.thumbnail alt="" />
      </div>
      <div class="tile__details" :class="{ tile__active: item.active }">
        <div class="tile__title">
          {{ item.typeName }}
        </div>
      </div>
    </div>

  </div>

</div>
</template>

<script>
export default {
  computed: {
    exteriorCarbonType() {
      return this.$store.getters.exteriorCarbonType
    }
  },
  methods: {
    outputCarbonType(id) {
      const storeSum = this.$store.state.exteriorSummary;
      const self = this.$store.state.exteriorCarbonType[id];
      storeSum.cType = self.type;

      if (this.$store.state.exteriorCarbonSpecials[1].active === true && self.type === 'F') {
        this.$store.state.exteriorCarbonSpecials[1].active = false;
        this.$store.state.exteriorCarbonSpecials[0].active = true;
        storeSum.cSpecial = this.$store.state.exteriorCarbonSpecials[0].special;
        this.$store.dispatch('changeCarbon');
      } else {
        this.$store.dispatch('changeCarbon');
      }

      this.$store.state.exteriorCarbonType.forEach(function(item) {
        item.active = false;
      })
      self.active = !self.active;

      this.$store.state.exteriorSummarySpecs[2].content = self.typeName;
      return;
    }
  }
}
</script>
