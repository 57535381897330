export default [{
    id: 'at1',
    type: '',
    accentTypeName: 'No accents',
    active: true
  },
  {
    id: 'at2',
    type: 'A',
    accentTypeName: 'Constant',
    active: false
  },
  {
    id: 'at3',
    type: 'B',
    accentTypeName: 'Segmented',
    active: false
  },
  {
    id: 'at4',
    type: 'C',
    accentTypeName: 'Dynamic',
    active: false
  }
]
