<template>
  <transition name="fade">
    <div id="exteriorFront34" class="bg" v-if="$store.state.views[0].show">
      <div id="base-f34" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.baseF34 + ')' }"></div>
      <div id="bodyColour-f34" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.bodyColourF34 + ')' }"></div>
      <div id="brakeDisc-f34" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.brakeDiscF34 + ')' }"></div>
      <div id="brakeCaliper-f34" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.brakeCaliperF34 + ')' }"></div>
      <div id="wheel-f34" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.wheelF34 + ')' }"></div>
      <div id="visibleCarbon-f34" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.visibleCarbonF34 + ')' }" alt="A"></div>
      <div id="accents-f34" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.accentsF34 + ')' }"></div>
      <!-- <div id="accentsWheel-f34" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.accentsWheelsF34 + ')' }"></div> -->
      <div id="logo-f34" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.logoF34 + ')' }"></div>
    </div>
    </transition>
</template>

<script>
export default {
};
</script>
