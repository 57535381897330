export default [
  {
      id: 'CSN',
      special: '',
      specialName: 'No specials',
      active: true,
      thumbnail: '/img/exterior/carbon/tiles/4x4.jpg',
    },
    {
      id: 'CSI',
      special: '+I',
      specialName: 'Inlays',
      active: false,
      thumbnail: '/img/exterior/carbon/tiles/4x4+I.jpg',
    },
    {
      id: 'CSW',
      special: '+W',
      specialName: 'Watermarks',
      active: false,
      thumbnail: '/img/exterior/carbon/tiles/4x4+W.jpg',
    }
]
