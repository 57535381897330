<template>
<!-- <div class="modal fade" id="specifications" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="container">
        <ul class="nav justify-content-end" style="margin-top: 15px;">
          <li class="nav-item">
            <button type="button" class="btn btn-outline-light" data-dismiss="modal">Close</button>
          </li>
        </ul> -->

        <div class="row row-specs" style="border:none;" ref="content">

          <div id="principal-specs" class="col-12 col-sm-6">
            <h1>Principal characteristics</h1>

            <div class="container text-white">
              <div class="row row-specs" :key="idx" v-for="(item, idx) in specs">
                <div class="col-3 tbl-row-name">
                  {{ item.name }}
                </div>
                <div class="col-9 tbl-row-content">
                  {{ item.content }}
                </div>
              </div>
            </div>
          </div>

          <div id="standard-specs" class="col-12 col-sm-6">
            <h1>Standard specification</h1>

            <div class="container text-white">
              <div class="row row-specs" :key="idx" v-for="(item, idx) in standards">
                <div class="col-3 tbl-row-name">
                  {{ item.name }}
                </div>
                <div class="col-9 tbl-row-content">
                  {{ item.content }}
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- </div>
      </div>
    </div>
  </div>
</div> -->
</template>

<script>
export default {
  data() {
    return {
        specs: [{
          name: 'Body',
          content: '2-door 2-seater, full carbon fibre body'
        },
        {
          name: 'Chassis',
          content: 'Lightweight steel/aluminium semi-monocoque, front and rear racing steel sub-frames'
        },
        {
          name: 'Length',
          content: '4,815 mm'
        },
        {
          name: 'Width',
          content: '2,038 mm (excluding door mirrors)'
        },
        {
          name: 'Height',
          content: '1,198 mm'
        },
        {
          name: 'Ground clearance',
          content: '95 mm (can be raised to 145 mm thanks to ride height adjustment, for added clearance of kerbs and speed humps)'
        },
        {
          name: 'Dry weight',
          content: '1,495 kg (in ultimate specification)'
        },
        {
          name: 'Engine',
          content: 'Zenvo 5.8-litre V8 flat-plane with twin centrifugal superchargers and sequential fuel injection​'
        },
        {
          name: 'Max power',
          content: '1,177 bhp'
        },
        {
          name: 'Transmission',
          content: 'Paddle shift 7-speed gearbox with helical-cut dog gears'
        },
        {
          name: 'Top speed',
          content: '325 km/h (202 mph) (electronically limited)'
        },
        {
          name: '0-100 km/h (0-62 mph)',
          content: '2.8 seconds'
        },
        {
          name: '0-200 km/h (0-124 mph)',
          content: '7.8 seconds'
        },
        {
          name: 'Brakes',
          content: 'Zenvo carbon ceramic discs with six-piston lightweight aluminium callipers, Front: 395 mm, Rear: 380 mm'
        },
        {
          name: 'Wheels',
          content: 'Zenvo forged aluminium wheels fitted with Michelin Pilot Sport Cup 2 tyres'
        },
        {
          name: 'Tyres, front',
          content: '8.5×20 wheels with 245/35-20 tyres'
        },
        {
          name: 'Tyres, rear',
          content: '12.5×21 wheels with 325/30-21 tyres'
        }],
  standards: [{
        name: 'Mechanical',
        content: 'Inconel exhaust system'
        },
        {
        name: '',
        content: 'Ride height elevation system front'
        },
        {
        name: '',
        content: 'Traction control'
        },
        {
        name: '',
        content: 'Dynamic speed senstive power steering'
        },
        {
        name: '',
        content: 'ABS and ESP assist & stability systems'
        },
        {
        name: '',
        content: 'Manually-adjustable performance settings, including variable power output'
        },
        {
        name: '',
        content: 'Dual gearbox set-up'
        },
        {
        name: '',
        content: 'Front and rear suspension: double wishbone, 3-way adjustable racing shock absorbers'
        },
        {
        name: '',
        content: 'Adjustable anti-roll bar'
        },
        {
        name: '',
        content: 'Centripetal wing'
        },
        {
        name: 'Interior',
        content: 'Electronic instrumentation'
        },
        {
        name: '',
        content: 'Power door locks'
        },
        {
        name: '',
        content: 'Keyless access and go with start button'
        },
        {
        name: '',
        content: 'Manually adjustable carbon racing shell seats'
        },
        {
        name: '',
        content: 'Full leather and Alcantara upholstery'
        },
        {
        name: '',
        content: 'Racing steering wheel with manual tilt'
        },
        {
        name: '',
        content: 'Electrically actuated handbrake'
        },
        {
        name: '',
        content: 'Theft-deterrent Cat.1 alarm system'
        },
        {
        name: 'Exterior',
        content: 'Light tinted glass'
        },
        {
        name: '',
        content: 'LED headlamps'
        },
        {
        name: '',
        content: 'LED daytime running lamps'
        },
        {
        name: '',
        content: 'LED rear lamps'
        }]
      }
    }

  }
;</script>
