<template>
<div class="tab-pane fade row" id="tab-content-logo" role="tabpanel">

  <div id="logosRow" class="row__inner">

    <div class="tile" @click="outputLogoOption(id)" :key="id" v-for="(item, id) in $store.state.exteriorLogos">
      <div class="tile__media">
        <img class="tile__img" :class="{ tile__up: item.active }" :src="'/img/exterior/logos/tiles/' + item.type + '.jpg'" alt="" />
      </div>
      <div class="tile__details" :class="{ tile__active: item.active }">
        <div class="tile__title">
          {{ item.typeName }}
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
export default {
  methods: {
    outputLogoOption(id) {
      const storeSum = this.$store.state.exteriorSummary;
      const self = this.$store.state.exteriorLogos[id];
      const part = self.type + '.png'
      storeSum.logoF34 = '/img/exterior/logos/front34/' + part;
      storeSum.logoF = '/img/exterior/logos/front/' + part;
      storeSum.logoR34 = '/img/exterior/logos/rear34/' + part;
      storeSum.logoR = '/img/exterior/logos/rear/' + part;
      storeSum.logoT = '/img/exterior/logos/top/' + part;
      storeSum.logoLF = '/img/exterior/logos/logof/' + part;
      storeSum.logoLR = '/img/exterior/logos/logor/' + part;

      this.$store.state.exteriorLogos.forEach(function (item) {
        item.active = false;
      })
      self.active = !self.active;

      this.$store.state.exteriorSummarySpecs[9].content = self.typeName;
      return;
    }
  }
}
</script>
