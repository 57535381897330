<template>
<div class="tab-pane fade row" id="tab-content-rims" role="tabpanel">

  <ul id="rimsTypeRow" class="nav sub-options" role="tablist">
    <li class="nav-item" :key="id" v-for="(item, id) in $store.state.exteriorRimsTypes" @click="outputRimType(id)" role="presentation">
      <a class="nav-link" :class="{ active: item.active }" data-toggle="pill" :href="'#rim' + item.type" role="tab">{{ item.rimTypeName }}</a>
    </li>
  </ul>

  <div class="tab-content" id="rims-tabContent">

    <div id="rimAluminum" class="tab-pane fade row__inner show active" role="tabpanel">

      <div class="tile" @click="outputRimAluminum(id)" :key="id" v-for="(item, id) in $store.state.exteriorRimsAluminum">
        <div class="tile__media">
          <img class="tile__img" :class="{ tile__up: item.active }" :src="'/img/exterior/wheels/tiles/Aluminum/' + item.colour + '.jpg'" alt="" />
        </div>
        <div class="tile__details" :class="{ tile__active: item.active }">
          <div class="tile__title">
            {{ item.rimColour }}
          </div>
        </div>
      </div>

    </div>

    <div id="rimCarbon4" class="tab-pane fade row__inner" role="tabpanel">

      <div class="tile" @click="outputRimCarbon4(id)" :key="id" v-for="(item, id) in $store.state.exteriorRimsCarbon4">
        <div class="tile__media">
          <img class="tile__img" :class="{ tile__up: item.active }" :src="'/img/exterior/wheels/tiles/Carbon/' + item.colour + '.jpg'" alt="" />
        </div>
        <div class="tile__details" :class="{ tile__active: item.active }">
          <div class="tile__title">
            {{ item.rimColour }}
          </div>
        </div>
      </div>

    </div>

    <div id="rimCarbonF" class="tab-pane fade row__inner" role="tabpanel">

      <div class="tile" @click="outputRimCarbonF(id)" :key="id" v-for="(item, id) in $store.state.exteriorRimsCarbonF">
        <div class="tile__media">
          <img class="tile__img" :class="{ tile__up: item.active }" :src="'/img/exterior/wheels/tiles/Carbon/' + item.colour + '.jpg'" alt="" />
        </div>
        <div class="tile__details" :class="{ tile__active: item.active }">
          <div class="tile__title">
            {{ item.rimColour }}
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
</template>

<script>
export default {
  computed: {
    exteriorRimsTypes() {
      return this.$store.getters.exteriorRimsTypes
    },
    exteriorRimsAluminum() {
      return this.$store.getters.exteriorRimsAluminum
    },
    exteriorRimsCarbon4() {
      return this.$store.getters.exteriorRimsCarbon4
    },
    exteriorRimsCarbonF() {
      return this.$store.getters.exteriorRimsCarbonF
    },
  },
  methods: {
    outputRimType(id) {
      const storeSum = this.$store.state.exteriorSummary;
      const self = this.$store.state.exteriorRimsTypes[id];
      storeSum.rType = self.type;
      storeSum.rTypeName = self.rimTypeName;
      return;
    },
    outputRimAluminum(id) {
      const storeSum = this.$store.state.exteriorSummary;
      const self = this.$store.state.exteriorRimsAluminum[id];
      storeSum.rColour = self.colour;

      this.$store.dispatch('changeRims');

      self.active = !self.active;

      storeSum.rColourName = self.rimColour;
      this.$store.state.exteriorSummarySpecs[7].content = storeSum.rTypeName + ' ' + storeSum.rColourName;
      return;
    },
    outputRimCarbon4(id) {
      const storeSum = this.$store.state.exteriorSummary;
      const self = this.$store.state.exteriorRimsCarbon4[id];
      storeSum.rColour = self.colour;

      this.$store.dispatch('changeRims');

      self.active = !self.active;

      storeSum.rColourName = self.rimColour;
      this.$store.state.exteriorSummarySpecs[7].content = storeSum.rTypeName + ' ' + storeSum.rColourName;
      return;
    },
    outputRimCarbonF(id) {
      const storeSum = this.$store.state.exteriorSummary;
      const self = this.$store.state.exteriorRimsCarbonF[id];
      storeSum.rColour = self.colour;

      this.$store.dispatch('changeRims');

      self.active = !self.active;

      storeSum.rColourName = self.rimColour;
      this.$store.state.exteriorSummarySpecs[7].content = storeSum.rTypeName + ' ' + storeSum.rColourName;
      return;
    }
  }
}
</script>
