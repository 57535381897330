<template>
<!-- <specs></specs> -->
<sum-spec></sum-spec>
<view-mobile></view-mobile>
<view-front34></view-front34>
<view-front></view-front>
<view-side></view-side>
<view-rear34></view-rear34>
<view-rear></view-rear>
<view-top></view-top>
<view-logo-f></view-logo-f>
<view-logo-r></view-logo-r>
<view-wheel></view-wheel>
<view-interior-1></view-interior-1>
<view-interior-2></view-interior-2>
<view-interior-3></view-interior-3>
<options-content></options-content>
<nav-bar></nav-bar>
</template>

<script>
export default {};
</script>
