export default [{
    id: 'us1',
    uSplit: 'B',
    uSplitName: 'Duotone seats split',
    active: false
  },
  {
    id: 'us2',
    uSplit: 'M',
    uSplitName: 'Monotone seats split',
    active: false
  },
  {
    id: 'us3',
    uSplit: 'S',
    uSplitName: 'Stitching only',
    active: true
  }
]
