<template>
  <div class="row row-specs" style="border:none;" ref="content">

    <div id="summary-text" class="col-12 col-sm-6">
      <h1>Your exterior summary</h1>

      <div class="container text-white">
        <div class="row row-specs" :key="idx" v-for="(item, idx) in $store.state.exteriorSummarySpecs">
          <div :id="item.id + '-name'" class="col-4 tbl-row-name">
            {{ item.name }}
          </div>
          <div :id="item.id" class="col-8 tbl-row-content">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>

    <div id="summary-images-ext" class="col-12 col-sm-6">
      <div class="container text-white">
        <div class="carousel slide carousel-fade" data-ride="carousel">
          <div class="carousel-inner">
            <div id="sum-front34-view" class="carousel-item active">
              <img id="sum-base-f34" :src="$store.state.exteriorSummary.baseF34" class="sum-option-layer d-block w-100">
              <img id="sum-bodyColour-f34" :src="$store.state.exteriorSummary.bodyColourF34" class="sum-option-layer d-block w-100">
              <img id="sum-brakeDisc-f34" :src="$store.state.exteriorSummary.brakeDiscF34" class="sum-option-layer d-block w-100">
              <img id="sum-brakeCaliper-f34" :src="$store.state.exteriorSummary.brakeCaliperF34" class="sum-option-layer d-block w-100">
              <img id="sum-wheel-f34" :src="$store.state.exteriorSummary.wheelF34" class="sum-option-layer d-block w-100">
              <img id="sum-visibleCarbon-f34" :src="$store.state.exteriorSummary.visibleCarbonF34" class="sum-option-layer d-block w-100">
              <img id="sum-accents-f34" :src="$store.state.exteriorSummary.accentsF34" class="sum-option-layer d-block w-100" />
              <!-- <img id="sum-accentsWheel-f34" :src="$store.state.exteriorSummary.accentsWheelsF34" class="sum-option-layer d-block w-100"> -->
              <img id="sum-logo-f34" :src="$store.state.exteriorSummary.logoF34" class="sum-option-layer d-block w-100">
            </div>
            <div id="sum-front-view" class="carousel-item">
              <img id="sum-base-f" :src="$store.state.exteriorSummary.baseF" class="sum-option-layer d-block w-100">
              <img id="sum-bodyColour-f" :src="$store.state.exteriorSummary.bodyColourF" class="sum-option-layer d-block w-100">
              <img id="sum-visibleCarbon-f" :src="$store.state.exteriorSummary.visibleCarbonF" class="sum-option-layer d-block w-100">
              <img id="sum-accents-f" :src="$store.state.exteriorSummary.accentsF" class="sum-option-layer d-block w-100" />
              <img id="sum-logo-f" :src="$store.state.exteriorSummary.logoF" class="sum-option-layer d-block w-100">
            </div>
            <div id="sum-side-view" class="carousel-item">
              <img id="sum-base-s" :src="$store.state.exteriorSummary.baseS" class="sum-option-layer d-block w-100">
              <img id="sum-bodyColour-s" :src="$store.state.exteriorSummary.bodyColourS" class="sum-option-layer d-block w-100">
              <img id="sum-brakeDisc-s" :src="$store.state.exteriorSummary.brakeDiscS" class="sum-option-layer d-block w-100">
              <img id="sum-brakeCaliper-s" :src="$store.state.exteriorSummary.brakeCaliperS" class="sum-option-layer d-block w-100">
              <img id="sum-wheel-s" :src="$store.state.exteriorSummary.wheelS" class="sum-option-layer d-block w-100">
              <img id="sum-visibleCarbon-s" :src="$store.state.exteriorSummary.visibleCarbonS" class="sum-option-layer d-block w-100">
              <img id="sum-accents-s" :src="$store.state.exteriorSummary.accentsS" class="sum-option-layer d-block w-100" />
              <!-- <img id="sum-accentsWheel-s" :src="$store.state.exteriorSummary.accentsWheelsS" class="sum-option-layer d-block w-100"> -->
            </div>
            <div id="sum-rear34-view" class="carousel-item">
              <img id="sum-base-r34" :src="$store.state.exteriorSummary.baseR34" class="sum-option-layer d-block w-100">
              <img id="sum-bodyColour-r34" :src="$store.state.exteriorSummary.bodyColourR34" class="sum-option-layer d-block w-100">
              <img id="sum-brakeDisc-r34" :src="$store.state.exteriorSummary.brakeDiscR34" class="sum-option-layer d-block w-100">
              <img id="sum-brakeCaliper-r34" :src="$store.state.exteriorSummary.brakeCaliperR34" class="sum-option-layer d-block w-100">
              <img id="sum-wheel-r34" :src="$store.state.exteriorSummary.wheelR34" class="sum-option-layer d-block w-100">
              <img id="sum-visibleCarbon-r34" :src="$store.state.exteriorSummary.visibleCarbonR34" class="sum-option-layer d-block w-100">
              <img id="sum-accents-r34" :src="$store.state.exteriorSummary.accentsR34" class="sum-option-layer d-block w-100" />
              <!-- <img id="sum-accentsWheel-r34" :src="$store.state.exteriorSummary.accentsWheelsR34" class="sum-option-layer d-block w-100"> -->
              <img id="sum-logo-r34" :src="$store.state.exteriorSummary.logoR34" class="sum-option-layer d-block w-100">
            </div>
            <div id="sum-rear-view" class="carousel-item">
              <img id="sum-base-r" :src="$store.state.exteriorSummary.baseR" class="sum-option-layer d-block w-100">
              <img id="sum-bodyColour-r" :src="$store.state.exteriorSummary.bodyColourR" class="sum-option-layer d-block w-100">
              <img id="sum-visibleCarbon-r" :src="$store.state.exteriorSummary.visibleCarbonR" class="sum-option-layer d-block w-100">
              <img id="sum-accents-r" :src="$store.state.exteriorSummary.accentsR" class="sum-option-layer d-block w-100" />
              <img id="sum-logo-r" :src="$store.state.exteriorSummary.logoR" class="sum-option-layer d-block w-100">
            </div>
            <div id="sum-top-view" class="carousel-item">
              <img id="sum-base-t" :src="$store.state.exteriorSummary.baseT" class="sum-option-layer d-block w-100">
              <img id="sum-bodyColour-t" :src="$store.state.exteriorSummary.bodyColourT" class="sum-option-layer d-block w-100">
              <img id="sum-brakeDisc-t" :src="$store.state.exteriorSummary.brakeDiscT" class="sum-option-layer d-block w-100">
              <img id="sum-brakeCaliper-t" :src="$store.state.exteriorSummary.brakeCaliperT" class="sum-option-layer d-block w-100">
              <img id="sum-wheel-t" :src="$store.state.exteriorSummary.wheelT" class="sum-option-layer d-block w-100">
              <img id="sum-visibleCarbon-t" :src="$store.state.exteriorSummary.visibleCarbonT" class="sum-option-layer d-block w-100">
              <img id="sum-accents-t" :src="$store.state.exteriorSummary.accentsT" class="sum-option-layer d-block w-100" />
              <!-- <img id="sum-accentsWheel-t" :src="$store.state.exteriorSummary.accentsWheelsT" class="sum-option-layer d-block w-100"> -->
              <img id="sum-logo-t" :src="$store.state.exteriorSummary.logoT" class="sum-option-layer d-block w-100">
            </div>
            <div id="sum-lf-view" class="carousel-item">
              <img id="sum-bodyColour-lf" :src="$store.state.exteriorSummary.bodyColourLF" class="sum-option-layer d-block w-100">
              <img id="sum-visibleCarbon-lf" :src="$store.state.exteriorSummary.visibleCarbonLF" class="sum-option-layer d-block w-100">
              <img id="sum-accents-lf" :src="$store.state.exteriorSummary.accentsLF" class="sum-option-layer d-block w-100" />
              <img id="sum-logo-lf" :src="$store.state.exteriorSummary.logoLF" class="sum-option-layer d-block w-100">
            </div>
            <div id="sum-lr-view" class="carousel-item">
              <img id="sum-bodyColour-lr" :src="$store.state.exteriorSummary.bodyColourLR" class="sum-option-layer d-block w-100">
              <img id="sum-visibleCarbon-lr" :src="$store.state.exteriorSummary.visibleCarbonLR" class="sum-option-layer d-block w-100">
              <img id="sum-accents-lr" :src="$store.state.exteriorSummary.accentsLR" class="sum-option-layer d-block w-100" />
              <img id="sum-logo-lr" :src="$store.state.exteriorSummary.logoLR" class="sum-option-layer d-block w-100">
            </div>
            <div id="sum-wheel-view" class="carousel-item">
              <img id="sum-base-w" :src="$store.state.exteriorSummary.baseW" class="sum-option-layer d-block w-100">
              <img id="sum-bodyColour-w" :src="$store.state.exteriorSummary.bodyColourW" class="sum-option-layer d-block w-100">
              <img id="sum-brakeDisc-w" :src="$store.state.exteriorSummary.brakeDiscW" class="sum-option-layer d-block w-100">
              <img id="sum-brakeCaliper-w4" :src="$store.state.exteriorSummary.brakeCaliperW" class="sum-option-layer d-block w-100">
              <img id="sum-wheel-w" :src="$store.state.exteriorSummary.wheelW" class="sum-option-layer d-block w-100">
              <img id="sum-visibleCarbon-w" :src="$store.state.exteriorSummary.visibleCarbonW" class="sum-option-layer d-block w-100">
              <img id="sum-accents-w" :src="$store.state.exteriorSummary.accentsW" class="sum-option-layer d-block w-100" />
              <!-- <img id="sum-accentsWheel-w" :src="$store.state.exteriorSummary.accentsWheelsW" class="sum-option-layer d-block w-100"> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>
