import { createStore } from 'vuex'
// import firebase from 'firebase'
// import db from '@/firebase/init'
// import exteriorAccentsWheels from './options/exterior-accents-wheels.js'
import exteriorAccentsColours from './options/exterior-accents-colours.js'
import exteriorAccentsColorsTypes from './options/exterior-accents-colors-types.js'
import exteriorAccentsTypes from './options/exterior-accents-types.js'
import exteriorBodyColours from './options/exterior-body-colors.js'
import exteriorBodyTypes from './options/exterior-body-types.js'
import exteriorCalipers from './options/exterior-calipers.js'
import exteriorCarbonOptions from './options/exterior-carbon-options.js'
import exteriorCarbonSpecials from './options/exterior-carbon-specials.js'
import exteriorCarbonTint from './options/exterior-carbon-tint.js'
import exteriorCarbonType from './options/exterior-carbon-type.js'
import exteriorCarbonPlaces from './options/exterior-carbon-places.js'
import exteriorLogos from './options/exterior-logos.js'
import exteriorRimsTypes from './options/exterior-rims-types.js'
import exteriorRimsAluminum from './options/exterior-rims-aluminum.js'
import exteriorRimsCarbon4 from './options/exterior-rims-carbon4.js'
import exteriorRimsCarbonF from './options/exterior-rims-carbonf.js'
import exteriorSummary from './options/exterior-summary.js'
import exteriorSummarySpecs from './options/exterior-summary-specs.js'
import interiorAccentsColors from './options/interior-accents-colours.js'
import interiorAluminum from './options/interior-aluminum.js'
import interiorAluminumTypes from './options/interior-aluminum-type.js'
import interiorHarness from './options/interior-harness.js'
import interiorCarbonType from './options/interior-carbon-type.js'
import interiorConfiguration from './options/interior-configuration.js'
import interiorSWStripe from './options/interior-sw-stripe.js'
import interiorUColors from './options/interior-upholstery-colors.js'
import interiorUSplits from './options/interior-upholstery-splits.js'
import interiorUTypes from './options/interior-upholstery-types.js'
import interiorSummary from './options/interior-summary.js'
import interiorSummarySpecs from './options/interior-summary-specs.js'
import views from './views/views.js'

// import mergeImages from 'merge-images';
// import {
//   Canvas,
//   Image
// } from 'canvas';

const store = createStore({
  state() {
    return {
      exteriorBodyColours,
      exteriorBodyTypes,
      exteriorAccentsTypes,
      exteriorAccentsColorsTypes,
      exteriorAccentsColours,
      exteriorCalipers,
      exteriorCarbonOptions,
      exteriorCarbonSpecials,
      exteriorCarbonTint,
      exteriorCarbonPlaces,
      exteriorCarbonType,
      exteriorLogos,
      exteriorRimsTypes,
      exteriorRimsAluminum,
      exteriorRimsCarbon4,
      exteriorRimsCarbonF,
      exteriorSummary,
      exteriorSummarySpecs,
      interiorAccentsColors,
      interiorAluminum,
      interiorAluminumTypes,
      interiorHarness,
      interiorCarbonType,
      interiorConfiguration,
      interiorSWStripe,
      interiorUColors,
      interiorUSplits,
      interiorUTypes,
      interiorSummary,
      interiorSummarySpecs,
      views
    }
  },
  getters: {
    exteriorCarbonOptions: state => state.exteriorCarbonOptions,
    exteriorCarbonType: state => state.exteriorCarbonType,
    exteriorCarbonPlace: state => state.exteriorCarbonPlace,
    exteriorCarbonTint: state => state.exteriorCarbonTint,
    exteriorCarbonSpecial: state => state.exteriorCarbonSpecial,
    exteriorAccentsTypes: state => state.exteriorAccentsTypes,
    exteriorAccentsColours: state =>  state.exteriorAccentsColours,
    exteriorRimsTypes: state => state.exteriorRimsTypes,
    exteriorRimsAluminum: state => state.exteriorRimsAluminum,
    exteriorRimsCarbon4: state => state.exteriorRimsCarbon4,
    exteriorRimsCarbonF: state => state.exteriorRimsCarbonF,
    interiorCarbonType: state => state.interiorCarbonType,
    interiorConfiguration: state => state.interiorConfiguration
  },
  mutations: {
    changeCarbon(state) {
      const part = state.exteriorSummary.cOption + '/' + state.exteriorSummary.cType + '/' + state.exteriorSummary.cPlace + '/' + state.exteriorSummary.cTint + state.exteriorSummary.cSpecial + '.png'
      state.exteriorSummary.visibleCarbonF34 = '/img/exterior/carbon/front34/' + part
      state.exteriorSummary.visibleCarbonF = '/img/exterior/carbon/front/' + part
      state.exteriorSummary.visibleCarbonR34 = '/img/exterior/carbon/rear34/' + part
      state.exteriorSummary.visibleCarbonR = '/img/exterior/carbon/rear/' + part
      state.exteriorSummary.visibleCarbonS = '/img/exterior/carbon/side/' + part
      state.exteriorSummary.visibleCarbonT = '/img/exterior/carbon/top/' + part
      state.exteriorSummary.visibleCarbonLF = '/img/exterior/carbon/logof/' + part
      state.exteriorSummary.visibleCarbonLR = '/img/exterior/carbon/logor/' + part
      state.exteriorSummary.visibleCarbonW = '/img/exterior/carbon/wheel/' + part
    },
    changeAccents(state) {
      if (state.exteriorSummary.aType === '') {
        const part = '/img/null.png'
        state.exteriorSummary.accentsF34 = part
        state.exteriorSummary.accentsF = part
        state.exteriorSummary.accentsR34 = part
        state.exteriorSummary.accentsR = part
        state.exteriorSummary.accentsS = part
        state.exteriorSummary.accentsT = part
        state.exteriorSummary.accentsLF = part
        state.exteriorSummary.accentsLR = part
        state.exteriorSummary.accentsW = part
      } else {
        const part = state.exteriorSummary.cOption + '/' + state.exteriorSummary.aType + '/' + state.exteriorSummary.aColour + '.png'
        state.exteriorSummary.accentsF34 = '/img/exterior/accents/front34/' + part
        state.exteriorSummary.accentsF = '/img/exterior/accents/front/' + part
        state.exteriorSummary.accentsR34 = '/img/exterior/accents/rear34/' + part
        state.exteriorSummary.accentsR = '/img/exterior/accents/rear/' + part
        state.exteriorSummary.accentsS = '/img/exterior/accents/side/' + part
        state.exteriorSummary.accentsT = '/img/exterior/accents/top/' + part
        state.exteriorSummary.accentsLF = '/img/exterior/accents/logof/' + part
        state.exteriorSummary.accentsLR = '/img/exterior/accents/logor/' + part
        state.exteriorSummary.accentsW = '/img/exterior/accents/wheel/' + part
      }
    },
    changeRims(state) {
      const part = state.exteriorSummary.rType + '/' + state.exteriorSummary.rColour + '.png'
      state.exteriorSummary.wheelF34 = '/img/exterior/wheels/front34/' + part
      state.exteriorSummary.wheelR34 = '/img/exterior/wheels/rear34/' + part
      state.exteriorSummary.wheelS = '/img/exterior/wheels/side/' + part
      state.exteriorSummary.wheelT = '/img/exterior/wheels/top/' + part
      state.exteriorSummary.wheelW = '/img/exterior/wheels/wheel/' + part
      state.exteriorRimsAluminum.forEach(function(item) {
        item.active = false;
      })
      state.exteriorRimsCarbon4.forEach(function(item) {
        item.active = false;
      })
      state.exteriorRimsCarbonF.forEach(function(item) {
        item.active = false;
      })
    },
    changeICarbon(state) {
      const storeSum = state.interiorSummary
      const part = storeSum.iConfiguration + '/' + storeSum.iCarbon + '.png'
      storeSum.carbon1 = '/img/interior/carbon/interior1/' + part;
      storeSum.carbon2 = '/img/interior/carbon/interior2/' + part;
      storeSum.carbon3 = '/img/interior/carbon/interior3/' + part;
    },
    changeUpholstery(state) {
      const storeSum = state.interiorSummary
      const part = storeSum.iConfiguration + '/' + storeSum.uType + '/' + storeSum.uSplit + '/' + storeSum.uColor + '.jpg'
      storeSum.upholstery1 = '/img/interior/upholstery/interior1/' + part;
      storeSum.upholstery2 = '/img/interior/upholstery/interior2/' + part;
      storeSum.upholstery3 = '/img/interior/upholstery/interior3/' + part;
    },
    changeStripe(state) {
      const storeSum = state.interiorSummary
      const part = storeSum.iConfiguration + '/' + storeSum.iStripe + '.png';
      storeSum.stripe1 = '/img/interior/stripe/interior1/' + part;
      storeSum.stripe2 = '/img/interior/stripe/interior2/' + part;
      storeSum.stripe3 = '/img/interior/stripe/interior3/' + part;
    },
    changeAluminum(state) {
      const storeSum = state.interiorSummary
      const part = storeSum.iConfiguration + '/' + storeSum.alColour + '.png';
      storeSum.aluminum1 = '/img/interior/aluminum/interior1/' + part;
      storeSum.aluminum2 = '/img/interior/aluminum/interior2/' + part;
      storeSum.aluminum3 = '/img/interior/aluminum/interior3/' + part;
      state.interiorAluminum.forEach(function(item) {
        item.active = false;
      })
    },
    // merge(state) {
    //   // MERGE EXTERIOR FRONT 3/4
    //   mergeImages([
    //     state.exteriorSummary.bodyColourF34,
    //     state.exteriorSummary.brakeDiscF34,
    //     state.exteriorSummary.brakeCaliperF34,
    //     state.exteriorSummary.wheelF34,
    //     state.exteriorSummary.visibleCarbonF34,
    //     state.exteriorSummary.accentsF34,
    //     // state.exteriorSummary.accentsWheelsF34,
    //     state.exteriorSummary.logoF34
    //   ], {
    //     Canvas: Canvas,
    //     Image: Image,
    //     format: 'image/jpeg'
    // }).then(front34 => state.exteriorSummary.mergedF34 = front34);
    //
    //   // MERGE EXTERIOR FRONT
    //   mergeImages([
    //     state.exteriorSummary.bodyColourF,
    //     state.exteriorSummary.visibleCarbonF,
    //     state.exteriorSummary.accentsF,
    //     state.exteriorSummary.logoF
    //   ], {
    //     Canvas: Canvas,
    //     Image: Image,
    //     format: 'image/jpeg'
    //   }).then(front => state.exteriorSummary.mergedF = front);
    //
    //   // MERGE EXTERIOR SIDE
    //   mergeImages([
    //     state.exteriorSummary.bodyColourS,
    //     state.exteriorSummary.brakeDiscS,
    //     state.exteriorSummary.brakeCaliperS,
    //     state.exteriorSummary.wheelS,
    //     state.exteriorSummary.visibleCarbonS,
    //     state.exteriorSummary.accentsS,
    //     // state.exteriorSummary.accentsWheelsS
    //   ], {
    //     Canvas: Canvas,
    //     Image: Image,
    //     format: 'image/jpeg'
    //   }).then(side => state.exteriorSummary.mergedS = side);
    //
    //   // MERGE EXTERIOR REAR 3/4
    //   mergeImages([
    //     state.exteriorSummary.bodyColourR34,
    //     state.exteriorSummary.brakeDiscR34,
    //     state.exteriorSummary.brakeCaliperR34,
    //     state.exteriorSummary.wheelR34,
    //     state.exteriorSummary.visibleCarbonR34,
    //     state.exteriorSummary.accentsR34,
    //     // state.exteriorSummary.accentsWheelsR34,
    //     state.exteriorSummary.logoR34
    //   ], {
    //     Canvas: Canvas,
    //     Image: Image,
    //     format: 'image/jpeg'
    //   }).then(rear34 => state.exteriorSummary.mergedR34 = rear34);
    //
    //   // MERGE EXTERIOR REAR
    //   mergeImages([
    //     state.exteriorSummary.bodyColourR,
    //     state.exteriorSummary.visibleCarbonR,
    //     state.exteriorSummary.accentsR,
    //     state.exteriorSummary.logoR
    //   ], {
    //     Canvas: Canvas,
    //     Image: Image,
    //     format: 'image/jpeg'
    //   }).then(rear => state.exteriorSummary.mergedR = rear);
    //
    //   // MERGE EXTERIOR TOP
    //   mergeImages([
    //     state.exteriorSummary.bodyColourT,
    //     state.exteriorSummary.brakeCaliperT,
    //     state.exteriorSummary.wheelT,
    //     state.exteriorSummary.visibleCarbonT,
    //     state.exteriorSummary.accentsT,
    //     // state.exteriorSummary.accentsWheelsT,
    //     state.exteriorSummary.logoT
    //   ], {
    //     Canvas: Canvas,
    //     Image: Image,
    //     format: 'image/jpeg'
    //   }).then(top => state.exteriorSummary.mergedT = top);
    //
    //   // MERGE LOGO FRONT
    //   mergeImages([
    //     state.exteriorSummary.bodyColourLF,
    //     state.exteriorSummary.visibleCarbonLF,
    //     state.exteriorSummary.accentsLF,
    //     state.exteriorSummary.logoLF
    //   ], {
    //     Canvas: Canvas,
    //     Image: Image,
    //     format: 'image/jpeg'
    //   }).then(logof => state.exteriorSummary.mergedLF = logof);
    //
    //   // MERGE LOGO REAR
    //   mergeImages([
    //     state.exteriorSummary.bodyColourLR,
    //     state.exteriorSummary.visibleCarbonLR,
    //     state.exteriorSummary.accentsLR,
    //     state.exteriorSummary.logoLR
    //   ], {
    //     Canvas: Canvas,
    //     Image: Image,
    //     format: 'image/jpeg'
    //   }).then(logor => state.exteriorSummary.mergedLR = logor);
    //
    //   // MERGE WHEEL
    //   mergeImages([
    //     state.exteriorSummary.bodyColourW,
    //     state.exteriorSummary.brakeDiscW,
    //     state.exteriorSummary.brakeCaliperW,
    //     state.exteriorSummary.wheelW,
    //     state.exteriorSummary.visibleCarbonW,
    //     state.exteriorSummary.accentsW,
    //     // state.exteriorSummary.accentsWheelsW,
    //   ], {
    //     Canvas: Canvas,
    //     Image: Image,
    //     format: 'image/jpeg'
    // }).then(wheel => state.exteriorSummary.mergedW = wheel);
    // },
  },
  actions: {
    changeCarbon(context) {
      context.commit('changeCarbon');
      context.commit('changeAccents');
    },
    changeAccents(context) {
      context.commit('changeAccents');
    },
    changeRims(context) {
      context.commit('changeRims');
    },
    changeICarbon(context) {
      context.commit('changeICarbon');
    },
    changeUpholstery(context) {
      context.commit('changeUpholstery');
    },
    changeStripe(context) {
      context.commit('changeStripe');
    },
    changeAluminum(context) {
      context.commit('changeAluminum');
    },
    merge(context) {
      context.commit('merge');
    }
  }
})

export default store;
