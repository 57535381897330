export default [{
    id: 'ra1',
    colour: 'Antracitegra',
    rimColour: 'Antracitegrå',
    active: false
  },
  {
    id: 'ra2',
    colour: 'Sort',
    rimColour: 'Sort',
    active: true
  },
  {
    id: 'ra3',
    colour: 'SvaerdSolv',
    rimColour: 'Sværd Sølv',
    active: false
  }
]
