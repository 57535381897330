export default [{
    id: 'iac1',
    colourName: 'Ishvid Perlemor',
    type: 'Metallisk',
    colour: 'Ishvid-Perlemor',
    active: false
  },
  {
    id: 'iac2',
    colourName: 'Sværd Sølv',
    type: 'Metallisk',
    colour: 'Svaerd-Solv',
    active: false
  },
  {
    id: 'iac3',
    colourName: 'Grus Grå',
    type: 'Metallisk',
    colour: 'Grus-Gra',
    active: false
  },
  {
    id: 'iac4',
    colourName: 'Kulsort',
    type: 'Metallisk',
    colour: 'Kulsort',
    active: false
  },
  {
    id: 'iac5',
    colourName: 'Lovgrøn',
    type: 'Metallisk',
    colour: 'Lovgron',
    active: false
  },
  {
    id: 'iac6',
    colourName: 'Gletcher Blå',
    type: 'Metallisk',
    colour: 'Gletcher-Bla',
    active: false
  },
  {
    id: 'iac7',
    colourName: 'Baltisk Blå',
    type: 'Metallisk',
    colour: 'Baltisk-Bla',
    active: false
  },
  {
    id: 'iac8',
    colourName: 'Lilla Dis',
    type: 'Metallisk',
    colour: 'Lilla-Dis',
    active: false
  },
  {
    id: 'iac9',
    colourName: 'Dansk Rød',
    type: 'Solid',
    colour: 'Dansk-Rod',
    active: false
  },
  {
    id: 'iac10',
    colourName: 'Rød Orange',
    type: 'Solid',
    colour: 'Rod-Orange',
    active: false
  },
  {
    id: 'iac11',
    colourName: 'Appelsin Orange',
    type: 'Solid',
    colour: 'Appelsin-Orange',
    active: false
  },
  {
    id: 'iac12',
    colourName: 'Citrongul',
    type: 'Solid',
    colour: 'Citrongul',
    active: false
  },
  {
    id: 'iac13',
    colourName: 'Lys Grøn',
    type: 'Solid',
    colour: 'Lysgron',
    active: false
  },
  {
    id: 'iac14',
    colourName: 'Skovgrøn',
    type: 'Solid',
    colour: 'Skovgron',
    active: false
  },
  {
    id: 'iac15',
    colourName: 'Fjordblå',
    type: 'Solid',
    colour: 'Fjordbla',
    active: false
  },
  {
    id: 'iac16',
    colourName: 'Granitgrå',
    type: 'Solid',
    colour: 'Granitgra',
    active: false
  }
]
