export default [{
    id: 'CT4',
    type: '4x4',
    typeName: '4x4',
    active: true,
    thumbnail: '/img/exterior/carbon/tiles/4x4.jpg',
  },
  {
    id: 'CTF',
    type: 'F',
    typeName: 'Fragmented',
    active: false,
    thumbnail: '/img/exterior/carbon/tiles/F.jpg',
  }
]
