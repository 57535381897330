export default [{
  id: 'sum-configuration',
  name: 'Interior configuration',
  content: 'Comfort: Driver and passenger\n' + 'airbag, sound system\n' + 'with detachable iPad Pro\n' + 'media interface (4G/WiFi),\n' + 'rear/front view camera'
},
{
  id: 'sum-harness',
  name: 'Harnesses',
  content: 'Kulsort'
},
{
  id: 'sum-int-carbon-type',
  name: 'Carbon type',
  content: '4x4'
},
{
  id: 'sum-upholstery',
  name: 'Upholstery',
  content: '3D fabric Stengrå, stitching only'
},
{
  id: 'sum-int-accents',
  name: 'Accents',
  content: 'No accents'
},
{
  id: 'sum-stripe',
  name: 'Steering wheel stripe',
  content: 'Rød'
},
{
  id: 'sum-aluminum',
  name: 'Aluminium parts surface treatment',
  content: 'Anodized, Sort'
}]
