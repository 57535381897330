<template>
<div class="tab-pane fade row" id="tab-content-ecc" role="tabpanel">

  <ul id="exteriorCarbonPlaceRow" class="nav sub-options">
    <li class="nav-item" @click="outputCarbonPlace(idx)" :key="idx" v-for="(item, idx) in $store.state.exteriorCarbonPlaces">
      <a class="nav-link" :id="item.id" :class="[{ active: item.active }, { 'd-none': item.id === 'CPT' && this.$store.state.exteriorSummary.cOption === 'A'}]">{{ item.placeName }}</a>
    </li>
  </ul>

  <div id="exteriorCarbonTint" class="row__inner">

    <div class="tile" @click="outputCarbonTint(id)" :key="id" v-for="(item, id) in $store.state.exteriorCarbonTint">
      <div class="tile__media">
        <img class="tile__img" :class="{ tile__up: item.active }" :src=item.thumbnail alt="" />
      </div>
      <div class="tile__details" :class="{ tile__active: item.active }">
        <div class="tile__title">
          {{ item.tintName }}
        </div>
      </div>
    </div>

  </div>

</div>
</template>

<script>
export default {
  computed: {
    exteriorCarbonTint() {
      return this.$store.getters.exteriorCarbonTint
    },
    exteriorCarbonPlace() {
      return this.$store.getters.exteriorCarbonPlace
    }
  },
  methods: {
    outputCarbonPlace(idx) {
      const storeSum = this.$store.state.exteriorSummary;
      const self = this.$store.state.exteriorCarbonPlaces[idx];
      storeSum.cPlace = self.place;

      this.$store.dispatch('changeCarbon');

      this.$store.state.exteriorCarbonPlaces.forEach(function(item) {
        item.active = false;
      })
      self.active = !self.active;

      this.$store.state.exteriorSummarySpecs[3].content = storeSum.cTint + ' ' + self.placeName;
      return;
    },
    outputCarbonTint(id) {
      const storeSum = this.$store.state.exteriorSummary;
      const self = this.$store.state.exteriorCarbonTint[id];
      storeSum.cTint = self.tint;

      this.$store.dispatch('changeCarbon');

      this.$store.state.exteriorCarbonTint.forEach(function(item) {
        item.active = false;
      })
      self.active = !self.active;

      this.$store.state.exteriorSummarySpecs[3].content = self.tintName + ' ' + storeSum.cPlace;
      return;
    }
  }
}
</script>
