export default [{
    id: 'CTiN',
    tint: 'NoTint',
    tintName: 'No tint',
    active: true,
    thumbnail: '/img/exterior/carbon/tiles/4x4.jpg',
  },
  {
    id: 'CTiB',
    tint: 'Blue',
    tintName: 'Blå',
    active: false,
    thumbnail: '/img/exterior/carbon/tiles/4x4+Bla.jpg',
  },
  {
    id: 'CTiR',
    tint: 'Red',
    tintName: 'Rød',
    active: false,
    thumbnail: '/img/exterior/carbon/tiles/4x4+Rod.jpg',
  },
  {
    id: 'CTiG',
    tint: 'Green',
    tintName: 'Grøn',
    active: false,
    thumbnail: '/img/exterior/carbon/tiles/4x4+Gron.jpg',
  },
  {
    id: 'CTiL',
    tint: 'Purple',
    tintName: 'Lilla',
    active: false,
    thumbnail: '/img/exterior/carbon/tiles/4x4+Lilla.jpg',
  },
]
