export default [{
    id: 'al1',
    alName: 'Blå',
    type: 'A',
    al: 'Bla',
    active: false
  },
  {
    id: 'al2',
    alName: 'Grøn',
    type: 'A',
    al: 'Gron',
    active: false
  },
  {
    id: 'al3',
    alName: 'Gul',
    type: 'A',
    al: 'Gul',
    active: false
  },
  {
    id: 'al4',
    alName: 'Rød',
    type: 'A',
    al: 'Rod',
    active: false
  },
  {
    id: 'al5',
    alName: 'Sort',
    type: 'A',
    al: 'Sort',
    active: true
  },
  {
    id: 'al6',
    alName: 'Guld',
    type: 'P',
    al: 'Guld',
    active: false
  },
  {
    id: 'al7',
    alName: 'Købber',
    type: 'P',
    al: 'Kobber',
    active: false
  },
  {
    id: 'al8',
    alName: 'Mørkt Rhodium',
    type: 'P',
    al: 'Morkt-Rhodium',
    active: false
  }
]
