export default [{
  id: 'sum-body-colour',
  name: 'Body colour',
  content: 'Metallisk Sværd Sølv'
},
{
  id: 'sum-evc',
  name: 'Carbon layout',
  content: 'Option A'
},
{
  id: 'sum-vct',
  name: 'Carbon type',
  content: '4x4'
},
{
  id: 'sum-vcc',
  name: 'Carbon tint',
  content: 'No tint, full'
},
{
  id: 'sum-specials',
  name: 'Carbon specials',
  content: 'No specials'
},
{
  id: 'sum-accents',
  name: 'Accent colour',
  content: 'No accents'
},
{
  id: 'sum-accents-wheels',
  name: 'Wheel accents',
  content: 'No accents'
},
{
  id: 'sum-rims',
  name: 'Wheels',
  content: 'Aluminium Sort'
},
{
  id: 'sum-brakes',
  name: 'Brake calipers',
  content: 'Kulsort'
},
{
  id: 'sum-logos',
  name: 'Logo pack',
  content: '1x1'
}]
