export default [{
    id: 'ac1',
    colourName: 'Ishvid Perlemor',
    type: 'Metallisk',
    colour: 'Ishvid-Perlemor',
    active: false
  },
  {
    id: 'ac2',
    colourName: 'Sværd Sølv',
    type: 'Metallisk',
    colour: 'Svaerd-Solv',
    active: false
  },
  {
    id: 'ac3',
    colourName: 'Grus Grå',
    type: 'Metallisk',
    colour: 'Grus-Gra',
    active: false
  },
  {
    id: 'ac4',
    colourName: 'Kulsort',
    type: 'Metallisk',
    colour: 'Kulsort',
    active: false
  },
  {
    id: 'ac5',
    colourName: 'Lovgrøn',
    type: 'Metallisk',
    colour: 'Lovgron',
    active: false
  },
  {
    id: 'ac6',
    colourName: 'Gletcher Blå',
    type: 'Metallisk',
    colour: 'Gletcher-Bla',
    active: false
  },
  {
    id: 'ac7',
    colourName: 'Baltisk Blå',
    type: 'Metallisk',
    colour: 'Baltisk-Bla',
    active: false
  },
  {
    id: 'ac8',
    colourName: 'Lilla Dis',
    type: 'Metallisk',
    colour: 'Lilla-Dis',
    active: false
  },
  {
    id: 'ac9',
    colourName: 'Dansk Rød',
    type: 'Solid',
    colour: 'Dansk-Rod',
    active: false
  },
  {
    id: 'ac10',
    colourName: 'Rød Orange',
    type: 'Solid',
    colour: 'Rod-Orange',
    active: false
  },
  {
    id: 'ac11',
    colourName: 'Appelsin Orange',
    type: 'Solid',
    colour: 'Appelsin-Orange',
    active: false
  },
  {
    id: 'ac12',
    colourName: 'Citrongul',
    type: 'Solid',
    colour: 'Citrongul',
    active: false
  },
  {
    id: 'ac13',
    colourName: 'Lys Grøn',
    type: 'Solid',
    colour: 'Lysgron',
    active: false
  },
  {
    id: 'ac14',
    colourName: 'Skovgrøn',
    type: 'Solid',
    colour: 'Skovgron',
    active: false
  },
  {
    id: 'ac15',
    colourName: 'Fjordblå',
    type: 'Solid',
    colour: 'Fjordbla',
    active: false
  },
  {
    id: 'ac16',
    colourName: 'Granitgrå',
    type: 'Solid',
    colour: 'Granitgra',
    active: false
  }
]
