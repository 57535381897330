<template>
<div class="tab-pane fade show row active" id="tab-content-bc" role="tabpanel">

    <ul id="bodyColoursRow" class="nav sub-options" role="tablist">
      <li class="nav-item" :key="item.type" v-for="(item) in this.$store.state.exteriorBodyTypes" role="presentation">
        <a class="nav-link" :class="{ active: item.active }" data-toggle="pill" :href="'#body' + item.type" role="tab">{{ item.typeName }}</a>
      </li>
    </ul>

    <div class="tab-content" id="body-tabContent">

      <div id="bodyMetallisk" class="tab-pane fade row__inner show active" role="tabpanel">

        <div class="tile" @click="outputColourOption(id)" :key="id" v-for="(item, id) in metallics">
          <div class="tile__media">
            <img class="tile__img" :class="{ tile__up: item.active }" :src="'/img/exterior/bodycolours/tiles/' + item.type + '-'+ item.colour + '.jpg'" alt="" />
          </div>
          <div class="tile__details" :class="{ tile__active: item.active }">
            <div class="tile__title">
              {{ item.colourName }}
            </div>
          </div>
        </div>

      </div>

      <div id="bodySolid" class="tab-pane fade row__inner" role="tabpanel">

        <div class="tile" @click="outputColourOption(id + 8)" :key="id + 8" v-for="(item, id) in solids">
          <div class="tile__media">
            <img class="tile__img" :class="{ tile__up: item.active }" :src="'/img/exterior/bodycolours/tiles/' + item.type + '-'+ item.colour + '.jpg'" alt="" />
          </div>
          <div class="tile__details" :class="{ tile__active: item.active }">
            <div class="tile__title">
              {{ item.colourName }}
            </div>
          </div>
        </div>

      </div>

    </div>
</div>
</template>

<script>
export default {
  computed: {
    metallics: function() {
      return this.$store.state.exteriorBodyColours.filter(i => i.type === 'Metallisk')
    },
    solids: function() {
      return this.$store.state.exteriorBodyColours.filter(i => i.type === 'Solid')
    }
  },
  methods: {
    outputColourOption(id) {
      const storeSum = this.$store.state.exteriorSummary;
      const self = this.$store.state.exteriorBodyColours[id];
      const part = self.type + '/' + self.colour + '.png'
      const partL = self.type + '/' + self.colour + '.jpg'
      storeSum.bodyColourF34 = '/img/exterior/bodycolours/front34/' + part;
      storeSum.bodyColourF = '/img/exterior/bodycolours/front/' + part;
      storeSum.bodyColourR34 = '/img/exterior/bodycolours/rear34/' + part;
      storeSum.bodyColourR = '/img/exterior/bodycolours/rear/' + part;
      storeSum.bodyColourS = '/img/exterior/bodycolours/side/' + part;
      storeSum.bodyColourT = '/img/exterior/bodycolours/top/' + part;
      storeSum.bodyColourLF = '/img/exterior/bodycolours/logof/' + partL;
      storeSum.bodyColourLR = '/img/exterior/bodycolours/logor/' + partL;
      storeSum.bodyColourW = '/img/exterior/bodycolours/wheel/' + part;

      this.$store.state.exteriorBodyColours.forEach(function(item) {
        item.active = false;
      })
      self.active = !self.active;

      this.$store.state.exteriorSummarySpecs[0].content = self.type + ' ' + self.colourName;
      // const vfunc = '"value=selectVariantSet(' + self.vset + ')")'
      // vred.executePython(vfunc);
      return;
    }
  }
}
</script>
