import {
  createApp
} from "vue";
import 'es6-promise/auto';
import store from './store/index.js';
import {
  createRouter,
  createWebHistory
} from 'vue-router';
import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import VueCookies from 'vue3-cookies'

import App from "./App.vue";
import Specs from "./components/Specifications.vue";
import SumSpec from "./components/Summary.vue";
import SumExt from "./components/Summary_Content_Exterior.vue";
import SumInt from "./components/Summary_Content_Interior.vue";
import ViewFront34 from "./components/ViewFront34.vue";
import ViewFront from "./components/ViewFront.vue";
import ViewRear34 from "./components/ViewRear34.vue";
import ViewRear from "./components/ViewRear.vue"
import ViewSide from "./components/ViewSide.vue";
import ViewTop from "./components/ViewTop.vue";
import ViewLogoF from "./components/ViewLogoF.vue";
import ViewLogoR from "./components/ViewLogoR.vue";
import ViewWheel from "./components/ViewWheel.vue";
import ViewMobile from "./components/ViewMobile.vue";
import ViewInterior1 from "./components/ViewInterior1.vue";
import ViewInterior2 from "./components/ViewInterior2.vue";
import ViewInterior3 from "./components/ViewInterior3.vue";
import NavBar from "./components/NavBar.vue";
import OptionsContent from "./components/OptionsContent.vue"
import BC from "./components/Option_BC.vue";
import ExteriorCarbonOption from "./components/Option_ExteriorCarbonOption.vue";
import ExteriorCarbonType from "./components/Option_ExteriorCarbonType.vue";
import ExteriorCarbonSpecial from "./components/Option_ExteriorCarbonSpecial.vue";
import ExteriorCarbonTint from "./components/Option_ExteriorCarbonTint.vue";
import AC from "./components/Option_AC.vue";
import Rims from "./components/Option_Rims.vue"
import Brakes from "./components/Option_Brakes.vue";
import Logos from "./components/Option_Logos.vue";
import InteriorConfiguration from "./components/Option_InteriorConfiguration.vue";
import Harness from "./components/Option_Harness.vue";
import InteriorCarbonType from "./components/Option_InteriorCarbonType.vue";
import Upholstery from "./components/Option_Upholstery.vue";
import SWStripe from "./components/Option_Stripe.vue";
import Aluminum from "./components/Option_Aluminum.vue";
import Configurator from "./components/Configurator.vue";
// import Account from "./components/Account.vue";
// import SignIn from "./components/SignIn.vue";
// import SignUp from "./components/SignUp.vue";
// import ForgotPassword from "./components/ForgotPassword.vue";

var firebaseConfig = {
  apiKey: "AIzaSyBMxliZSsuJIs0m8UN23iuuMm0FjWNQrCQ",
  authDomain: "zenvo-configurator.firebaseapp.com",
  databaseURL: "https://zenvo-configurator.firebaseio.com",
  projectId: "zenvo-configurator",
  storageBucket: "zenvo-configurator.appspot.com",
  messagingSenderId: "387002231035",
  appId: "1:387002231035:web:25fc9070fa59bb44d9285e",
  measurementId: "G-07V0FDBPEH"
};
// Initialize Firebase

firebase.initializeApp(firebaseConfig);

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: Configurator,
      meta: { requiresAuth: true }
    },
    // {
    //   path: '/sign-in',
    //   component: SignIn
    // },
    // {
    //   path: '/sign-up',
    //   component: SignUp
    // },
    // { path: '/forgotpassword', component: ForgotPassword}
  ]
});
//
// firebase.auth().onAuthStateChanged((user) => {
//     if (user) {
//       router.replace('/');
//     }
// });
//
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (firebase.auth().currentUser) {
//       next();
//     } else {
//       next({
//         path: '/sign-in',
//       });
//     }
//   } else {
//     next();
//   }
// });

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueCookies, {
    expireTimes: "30d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None"
});

app.component('specs', Specs);
app.component('sum-spec', SumSpec);
app.component('sum-ext', SumExt);
app.component('sum-int', SumInt);
app.component('view-front34', ViewFront34);
app.component('view-front', ViewFront);
app.component('view-rear34', ViewRear34);
app.component('view-rear', ViewRear);
app.component('view-side', ViewSide);
app.component('view-top', ViewTop);
app.component('view-logo-f', ViewLogoF);
app.component('view-logo-r', ViewLogoR);
app.component('view-wheel', ViewWheel);
app.component('view-interior-1', ViewInterior1);
app.component('view-interior-2', ViewInterior2);
app.component('view-interior-3', ViewInterior3);
app.component('view-mobile', ViewMobile);
app.component('nav-bar', NavBar);
app.component('options-content', OptionsContent);
app.component('option-bc', BC);
app.component('option-eco', ExteriorCarbonOption);
app.component('option-ect', ExteriorCarbonType);
app.component('option-ecs', ExteriorCarbonSpecial);
app.component('option-ecc', ExteriorCarbonTint);
app.component('accents', AC);
app.component('rims', Rims);
app.component('brakes', Brakes);
app.component('logos', Logos);
app.component('interior-configuration', InteriorConfiguration);
app.component('interior-harness', Harness);
app.component('interior-carbon', InteriorCarbonType);
app.component('interior-upholstery', Upholstery);
app.component('interior-stripe', SWStripe);
app.component('interior-aluminum', Aluminum);

app.mount("#app");
