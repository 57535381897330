<template>
  <div id="interior1" class="bg" v-if="$store.state.views[9].show">
    <div id="upholstery-i1" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.interiorSummary.upholstery1 + ')' }"></div>
    <div id="carbon-i1" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.interiorSummary.carbon1 + ')' }"></div>
    <div id="stripe-i1" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.interiorSummary.stripe1 + ')' }"></div>
    <div id="harness-i1" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.interiorSummary.harness1 + ')' }"></div>
    <div id="aluminum-i1" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.interiorSummary.aluminum1 + ')' }"></div>
    <div id="accents-i1" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.interiorSummary.accents1 + ')' }"></div>
  </div>
</template>

<script>
  export default {
  };
</script>
