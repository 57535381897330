export default [
  {
    id: 'brake1',
    colourName: 'Dansk Rød',
    type: 'Solid',
    colour: 'Dansk-Rod',
    active: false
  },
  {
    id: 'brake2',
    colourName: 'Rød Orange',
    type: 'Solid',
    colour: 'Rod-Orange',
    active: false
  },
  {
    id: 'brake3',
    colourName: 'Appelsin Orange',
    type: 'Solid',
    colour: 'Appelsin-Orange',
    active: false
  },
  {
    id: 'brake4',
    colourName: 'Citrongul',
    type: 'Solid',
    colour: 'Citrongul',
    active: false
  },
  {
    id: 'brake5',
    colourName: 'Lys Grøn',
    type: 'Solid',
    colour: 'Lysgron',
    active: false
  },
  {
    id: 'brake6',
    colourName: 'Skovgrøn',
    type: 'Solid',
    colour: 'Skovgron',
    active: false
  },
  {
    id: 'brake7',
    colourName: 'Fjordblå',
    type: 'Solid',
    colour: 'Fjordbla',
    active: false
  },
  {
    id: 'brake8',
    colourName: 'Granitgrå',
    type: 'Solid',
    colour: 'Granitgra',
    active: false
  },
  {
    id: 'brake9',
    colourName: 'Kulsort',
    type: 'Metallisk',
    colour: 'Kulsort',
    active: true
  }
]
