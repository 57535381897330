export default [{
    id: 'ICT4',
    type: 'Comfort',
    typeDesc: 'Driver and passenger\n' + 'airbag, sound system\n' + 'with detachable iPad Pro\n' + 'media interface (4G/WiFi),\n' + 'rear/front view camera',
    active: true
  },
  {
    id: 'ICTF',
    type: 'Track',
    typeDesc: 'Suede racing steering\n' + 'wheel, detachable iPad Pro\n' + 'media interface (4G/WiFi),\n' + 'rear/front view camera',
    active: false
  }
]
