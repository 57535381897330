export default [
  {
      id: 'CPB',
      place: 'Bottom',
      placeName: 'Bottom',
      active: false,
    },
    {
      id: 'CPT',
      place: 'Top',
      placeName: 'Top',
      active: false,
    },
    {
      id: 'CPF',
      place: 'Full',
      placeName: 'Full',
      active: true,
    }
]
