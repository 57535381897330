<template>
  <transition name="fade">
  <div id="exteriorFront" class="bg" v-if="$store.state.views[1].show">
    <div id="base-f" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.baseF + ')' }"></div>
    <div id="bodyColour-f" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.bodyColourF + ')' }"></div>
    <div id="visibleCarbon-f" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.visibleCarbonF + ')' }"></div>
    <div id="accents-f" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.accentsF + ')' }"></div>
    <div id="logo-f" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.logoF + ')' }"></div>
  </div>
</transition>
</template>

<script>
  export default {
  };
</script>
