export default [{
    id: 'rcf1',
    colour: 'FNo',
    rimColour: 'No tint',
    active: false
  },
  {
    id: 'rcf2',
    colour: 'FRod',
    rimColour: 'Rød',
    active: false
  },
  {
    id: 'rcf3',
    colour: 'FGron',
    rimColour: 'Grøn',
    active: false
  },
  {
    id: 'rcf4',
    colour: 'FBla',
    rimColour: 'Blå',
    active: false
  },
  {
    id: 'rcf5',
    colour: 'FLilla',
    rimColour: 'Lilla',
    active: false
  }
]
