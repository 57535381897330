export default [{
  id: 'bc1',
  colourName: 'Ishvid Perlemor',
  type: 'Metallisk',
  colour: 'Ishvid-Perlemor',
  active: false,
  vset: 'BC Metallisk Ishvid Perlemor'
}, {
  id: 'bc2',
  colourName: 'Sværd Sølv',
  type: 'Metallisk',
  colour: 'Svaerd-Solv',
  active: true,
  vset: 'BC Metallisk Svaerd Solv'
}, {
  id: 'bc3',
  colourName: 'Grus Grå',
  type: 'Metallisk',
  colour: 'Grus-Gra',
  active: false,
  vset: 'BC Metallisk Grus Gra'
}, {
  id: 'bc4',
  colourName: 'Kulsort',
  type: 'Metallisk',
  colour: 'Kulsort',
  active: false,
  vset: 'BC Metallisk Kulsort'
}, {
  id: 'bc5',
  colourName: 'Lovgrøn',
  type: 'Metallisk',
  colour: 'Lovgron',
  active: false,
  vset: 'BC Metallisk Lovgron'
}, {
  id: 'bc6',
  colourName: 'Gletcher Blå',
  type: 'Metallisk',
  colour: 'Gletcher-Bla',
  active: false,
  vset: 'BC Metallisk Gletcher Bla'
}, {
  id: 'bc7',
  colourName: 'Baltisk Blå',
  type: 'Metallisk',
  colour: 'Baltisk-Bla',
  active: false,
  vset: 'BC Metallisk Baltisk Bla'
}, {
  id: 'bc8',
  colourName: 'Lilla Dis',
  type: 'Metallisk',
  colour: 'Lilla-Dis',
  active: false,
  vset: 'BC Metallisk Lilla Dis'
}, {
  id: 'bc9',
  colourName: 'Dansk Rød',
  type: 'Solid',
  colour: 'Dansk-Rod',
  active: false,
  vset: 'BC Solid Dansk Rod'
}, {
  id: 'bc10',
  colourName: 'Rød Orange',
  type: 'Solid',
  colour: 'Rod-Orange',
  active: false,
  vset: 'BC Solid Red Orange'
}, {
  id: 'bc11',
  colourName: 'Appelsin Orange',
  type: 'Solid',
  colour: 'Appelsin-Orange',
  active: false,
  vset: 'BC Solid Appelsin Orange'
}, {
  id: 'bc12',
  colourName: 'Citrongul',
  type: 'Solid',
  colour: 'Citrongul',
  active: false,
  vset: 'BC Solid Citrongul'
}, {
  id: 'bc13',
  colourName: 'Lys Grøn',
  type: 'Solid',
  colour: 'Lysgron',
  active: false,
  vset: 'BC Solid Lys Gron'
}, {
  id: 'bc14',
  colourName: 'Skovgrøn',
  type: 'Solid',
  colour: 'Skovgron',
  active: false,
  vset: 'BC Solid Skovgron'
}, {
  id: 'bc15',
  colourName: 'Fjordblå',
  type: 'Solid',
  colour: 'Fjordbla',
  active: false,
  vset: 'BC Solid Fjordbla'
}, {
  id: 'bc16',
  colourName: 'Granitgrå',
  type: 'Solid',
  colour: 'Granitgra',
  active: false,
  vset: 'BC Solid Granitgra'
}]
