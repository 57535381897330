<template>
<div class="tab-pane fade row" id="tab-content-ecs" role="tabpanel">

  <div id="exteriorCarbonSpecial" class="row__inner">

    <div class="tile" :class="[{ 'd-none': item.id === 'CSI' && this.$store.state.exteriorSummary.cType === 'F'}]" @click="outputCarbonSpecial(id)" :key="id" v-for="(item, id) in $store.state.exteriorCarbonSpecials">
      <div class="tile__media">
        <img class="tile__img" :class="{ tile__up: item.active }" :src=item.thumbnail alt="" />
      </div>
      <div class="tile__details" :class="{ tile__active: item.active }">
        <div class="tile__title">
          {{ item.specialName }}
        </div>
      </div>
    </div>

  </div>

</div>
</template>

<script>
export default {
  computed: {
    exteriorCarbonSpecial() {
      return this.$store.getters.exteriorCarbonSpecial
    }
  },
  methods: {
    outputCarbonSpecial(id) {
      const storeSum = this.$store.state.exteriorSummary;
      const self = this.$store.state.exteriorCarbonSpecials[id];
      storeSum.cSpecial = self.special;

      this.$store.state.exteriorCarbonSpecials.forEach(function(item) {
        item.active = false;
      })
      self.active = !self.active;

      this.$store.dispatch('changeCarbon');

      this.$store.state.exteriorSummarySpecs[4].content = self.specialName + '';
      return;
    }
  }
}
</script>
