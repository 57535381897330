<template>
  <div id="exteriorRear34" class="bg" v-if="$store.state.views[3].show">
    <div id="base-r34" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.baseR34 + ')' }"></div>
    <div id="bodyColour-r34" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.bodyColourR34 + ')' }"></div>
    <div id="brakeDisc-r34" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.brakeDiscR34 + ')' }"></div>
    <div id="brakeCaliper-r34" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.brakeCaliperR34 + ')' }"></div>
    <div id="wheel-r34" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.wheelR34 + ')' }"></div>
    <div id="visibleCarbon-r34" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.visibleCarbonR34 + ')' }" alt="A"></div>
    <div id="accents-r34" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.accentsR34 + ')' }"></div>
    <!-- <div id="accentsWheel-r34" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.accentsWheelsR34 + ')' }"></div> -->
    <div id="logo-r34" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.logoR34 + ')' }"></div>
  </div>
</template>

<script>
  export default {

  };
</script>
