export default {
  // FRONT 34
  baseF34: '/img/exterior/bodycolours/front34/Metallisk/Svaerd-Solv.jpg',
  bodyColourF34: '/img/exterior/bodycolours/front34/Metallisk/Svaerd-Solv.png',
  brakeDiscF34: '/img/exterior/Wheel_BrakeDisc.png',
  brakeCaliperF34: '/img/exterior/brakes/front34/Kulsort.png',
  wheelF34: '/img/exterior/wheels/front34/Aluminum/Sort.png',
  visibleCarbonF34: '/img/exterior/carbon/front34/A/4x4/Full/NoTint.png',
  accentsF34: '/img/null.png',
  accentsWheelsF34: '/img/null.png',
  logoF34: '/img/exterior/logos/front34/1x1.png',

  // FRONT
  baseF: '/img/exterior/bodycolours/front/Metallisk/Svaerd-Solv.jpg',
  bodyColourF: '/img/exterior/bodycolours/front/Metallisk/Svaerd-Solv.png',
  visibleCarbonF: '/img/exterior/carbon/front/A/4x4/Full/NoTint.png',
  accentsF: '/img/null.png',
  logoF: '/img/exterior/logos/front/1x1.png',

  // REAR 34
  baseR34: '/img/exterior/bodycolours/rear34/Metallisk/Svaerd-Solv.jpg',
  bodyColourR34: '/img/exterior/bodycolours/rear34/Metallisk/Svaerd-Solv.png',
  brakeDiscR34: '/img/exterior/Wheel_BrakeDisc_Rear34.png',
  brakeCaliperR34: '/img/exterior/brakes/rear34/Kulsort.png',
  wheelR34: '/img/exterior/wheels/rear34/Aluminum/Sort.png',
  visibleCarbonR34: '/img/exterior/carbon/rear34/A/4x4/Full/NoTint.png',
  accentsR34: '/img/null.png',
  accentsWheelsR34: '/img/null.png',
  logoR34: '/img/exterior/logos/rear34/1x1.png',

  // REAR
  baseR: '/img/exterior/bodycolours/rear/Metallisk/Svaerd-Solv.jpg',
  bodyColourR: '/img/exterior/bodycolours/rear/Metallisk/Svaerd-Solv.png',
  visibleCarbonR: '/img/exterior/carbon/rear/A/4x4/Full/NoTint.png',
  accentsR: '/img/null.png',
  logoR: '/img/exterior/logos/rear/1x1.png',

  // SIDE
  baseS: '/img/exterior/bodycolours/side/Metallisk/Svaerd-Solv.jpg',
  bodyColourS: '/img/exterior/bodycolours/side/Metallisk/Svaerd-Solv.png',
  brakeDiscS: '/img/exterior/Wheel_BrakeDisc_Side.png',
  brakeCaliperS: '/img/exterior/brakes/side/Kulsort.png',
  wheelS: '/img/exterior/wheels/side/Aluminum/Sort.png',
  visibleCarbonS: '/img/exterior/carbon/side/A/4x4/Full/NoTint.png',
  accentsS: '/img/null.png',
  accentsWheelsS: '/img/null.png',

  // TOP
  baseT: '/img/exterior/bodycolours/top/Metallisk/Svaerd-Solv.jpg',
  bodyColourT: '/img/exterior/bodycolours/top/Metallisk/Svaerd-Solv.png',
  brakeCaliperT: '/img/exterior/brakes/top/Kulsort.png',
  wheelT: '/img/exterior/wheels/top/Aluminum/Sort.png',
  visibleCarbonT: '/img/exterior/carbon/top/A/4x4/Full/NoTint.png',
  accentsT: '/img/null.png',
  accentsWheelsT: '/img/null.png',
  logoT: '/img/exterior/logos/top/1x1.png',

  // LOGO FRONT
  bodyColourLF: '/img/exterior/bodycolours/logof/Metallisk/Svaerd-Solv.jpg',
  visibleCarbonLF: '/img/exterior/carbon/logof/A/4x4/Full/NoTint.png',
  accentsLF: '/img/null.png',
  logoLF: '/img/exterior/logos/logof/1x1.png',

  // LOGO REAR
  bodyColourLR: '/img/exterior/bodycolours/logor/Metallisk/Svaerd-Solv.jpg',
  visibleCarbonLR: '/img/exterior/carbon/logor/A/4x4/Full/NoTint.png',
  accentsLR: '/img/null.png',
  logoLR: '/img/exterior/logos/logor/1x1.png',

  // WHEEL
  baseW: '/img/exterior/bodycolours/wheel/Metallisk/Svaerd-Solv.jpg',
  bodyColourW: '/img/exterior/bodycolours/wheel/Metallisk/Svaerd-Solv.png',
  brakeDiscW: '/img/exterior/Wheel_BrakeDisc_Wheel.png',
  brakeCaliperW: '/img/exterior/brakes/wheel/Kulsort.png',
  wheelW: '/img/exterior/wheels/wheel/Aluminum/Sort.png',
  visibleCarbonW: '/img/exterior/carbon/wheel/A/4x4/Full/NoTint.png',
  accentsW: '/img/null.png',
  accentsWheelsW: '/img/null.png',

  // MERGES
  mergedF34: '',
  mergedF: '',
  mergedS: '',
  mergedR34: '',
  mergedR: '',
  mergedT: '',
  mergedW: '',

  // CARBON TEMP
  cOption: 'A',
  cType: '4x4',
  cPlace: 'Full',
  cTint: 'NoTint',
  cSpecial: '',

  // ACCENTS TEMP
  aType: '',
  aTypeName: 'No accents',
  aColour:' null.png',
  aColourName: '',

  // RIMS TEMP
  rType: 'Aluminum',
  rTypeName: 'Aluminum',
  rColour: 'Sort',
  rColourName: 'Sort',

  logo: '/img/Zenvo_TSRs_Logo_Full.jpg',

  exteriorTab: true,
  interiorTab: false
}
