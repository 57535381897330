<template>
<div class="tab-content" id="switching-tabContent">

  <div class="tab-pane fade options-exterior" :class="{ active: this.$store.state.exteriorSummary.exteriorTab, show: this.$store.state.exteriorSummary.exteriorTab }" id="tab-content-exterior"  role="tabpanel">
    <nav>
      <div class="options-tabs">
        <ul class="nav nav-pills mb-3" id="exterior-pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-bc" data-toggle="pill" href="#tab-content-bc" role="tab">Body colour</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-evc" data-toggle="pill" href="#tab-content-eco" role="tab">Carbon layout</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-vc-type" data-toggle="pill" href="#tab-content-ect" role="tab">Carbon type</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-evc-s" data-toggle="pill" href="#tab-content-ecs" role="tab">Carbon specials</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-vc-tint" data-toggle="pill" href="#tab-content-ecc" role="tab">Carbon tint</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-accents" data-toggle="pill" href="#tab-content-accents" role="tab">Accents</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-rims" data-toggle="pill" href="#tab-content-rims" role="tab">Wheels</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-brakes" data-toggle="pill" href="#tab-content-brakes" role="tab">Brake calipers</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-logo" data-toggle="pill" href="#tab-content-logo" role="tab">Logos</a>
          </li>
        </ul>
      </div>
    </nav>


    <div class="tab-content" id="pills-tabContent">
      <option-bc></option-bc>
      <option-eco></option-eco>
      <option-ect></option-ect>
      <option-ecs></option-ecs>
      <option-ecc></option-ecc>
      <accents></accents>
      <rims></rims>
      <brakes></brakes>
      <logos></logos>
    </div>
  </div>

  <div class="tab-pane fade options-interior" id="tab-content-interior" :class="{ active: this.$store.state.exteriorSummary.interiorTab, show: this.$store.state.exteriorSummary.interiorTab }"  role="tabpanel">
    <nav>
      <div class="options-tabs">
        <ul class="nav nav-pills mb-3" id="interior-pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-ic" data-toggle="pill" href="#tab-content-ic" role="tab">Configuration</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-harness" data-toggle="pill" href="#tab-content-harness" role="tab">Harnesses</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-ict" data-toggle="pill" href="#tab-content-ict" role="tab">Carbon type</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-upholstery" data-toggle="pill" href="#tab-content-upholstery" role="tab">Upholstery</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-stripe" data-toggle="pill" href="#tab-content-stripe" role="tab">Steering wheel stripe</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-aluminum" data-toggle="pill" href="#tab-content-aluminum" role="tab">Aluminium</a>
          </li>
        </ul>
      </div>
    </nav>


    <div class="tab-content" id="pills-tabContent">
      <interior-configuration></interior-configuration>
      <interior-harness></interior-harness>
      <interior-carbon></interior-carbon>
      <interior-upholstery></interior-upholstery>
      <interior-stripe></interior-stripe>
      <interior-aluminum></interior-aluminum>
    </div>
  </div>

</div>
</template>

<script>
export default {

}
</script>
