<template>
<div class="tab-pane fade row" id="tab-content-stripe" role="tabpanel">

  <div id="interiorStripe" class="row__inner">

    <div class="tile" @click="outputStripe(id)" :key="id" v-for="(item, id) in $store.state.interiorSWStripe">
      <div class="tile__media">
        <img class="tile__img" :class="{ tile__up: item.active }" :src="'/img/interior/stripe/thumbnails/' + item.colour + '.jpg'" alt="" />
      </div>
      <div class="tile__details" :class="{ tile__active: item.active }">
        <div class="tile__title">
          {{ item.colourName }}
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
export default {
  methods: {
    outputStripe(id) {
      const storeSum = this.$store.state.interiorSummary;
      const self = this.$store.state.interiorSWStripe[id];
      storeSum.iStripe = self.colour;

      this.$store.dispatch('changeStripe');

      this.$store.state.interiorSWStripe.forEach(function (item) {
        item.active = false;
      })
      self.active = !self.active;

      this.$store.state.interiorSummarySpecs[5].content = storeSum.iStripe;
      return;
    }
  }
}
</script>
