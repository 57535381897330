<template>
<div class="modal fade" id="summary-spec" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="container">

        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="tab-ext-sum" data-toggle="pill" href="#sum-content-exterior" role="tab">Exterior</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="tab-int-sum" data-toggle="pill" href="#sum-content-interior" role="tab">Interior</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="tab-specs-sum" data-toggle="pill" href="#sum-content-specs" role="tab">Specifications</a>
          </li>
          <li class="nav-item">
            <button class="btn btn-outline-light" data-dismiss="modal">Close</button>
          </li>
          <li class="nav-item">
            <button class="btn btn-outline-light noHover" v-if="this.generating">Creating PDF
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;background:transparent;display:inline-block;" width="15px" height="15px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="30" stroke-dasharray="20 7">
                  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="2" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                </circle>
              </svg>
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-outline-light" @click="savePDF()" v-if="this.show">Save to PDF</button>
          </li>
        </ul>

        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="sum-content-exterior" role="tabpanel">
            <sum-ext></sum-ext>
          </div>
          <div class="tab-pane fade" id="sum-content-interior" role="tabpanel">
            <sum-int></sum-int>
          </div>
          <div class="tab-pane fade" id="sum-content-specs" role="tabpanel">
            <specs></specs>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
</template>

<script>
import mergeImages from 'merge-images';
import {
  Canvas,
  Image
} from 'canvas';
import jsPDF from "jspdf";
export default {
  data() {
    return {
        show: true,
        generating: false
    }
  },
  methods: {
      savePDF() {

        // TURN OFF SAVE BUTTON
        this.show = false;
        this.generating = true;

        // START MERGING OF IMAGES

          // MERGE EXTERIOR FRONT 3/4
          mergeImages([
            this.$store.state.exteriorSummary.baseF34,
            this.$store.state.exteriorSummary.bodyColourF34,
            this.$store.state.exteriorSummary.brakeDiscF34,
            this.$store.state.exteriorSummary.brakeCaliperF34,
            this.$store.state.exteriorSummary.wheelF34,
            this.$store.state.exteriorSummary.visibleCarbonF34,
            this.$store.state.exteriorSummary.accentsF34,
            // this.$store.state.exteriorSummary.accentsWheelsF34,
            this.$store.state.exteriorSummary.logoF34
          ], {
            Canvas: Canvas,
            Image: Image,
            format: 'image/jpeg'
          }).then(front34 => this.$store.state.exteriorSummary.mergedF34 = front34);

          // MERGE EXTERIOR FRONT
          mergeImages([
            this.$store.state.exteriorSummary.baseF,
            this.$store.state.exteriorSummary.bodyColourF,
            this.$store.state.exteriorSummary.visibleCarbonF,
            this.$store.state.exteriorSummary.accentsF,
            this.$store.state.exteriorSummary.logoF
          ], {
            Canvas: Canvas,
            Image: Image,
            format: 'image/jpeg'
          }).then(front => this.$store.state.exteriorSummary.mergedF = front);

            // MERGE EXTERIOR SIDE
            mergeImages([
              this.$store.state.exteriorSummary.baseS,
              this.$store.state.exteriorSummary.bodyColourS,
              this.$store.state.exteriorSummary.brakeDiscS,
              this.$store.state.exteriorSummary.brakeCaliperS,
              this.$store.state.exteriorSummary.wheelS,
              this.$store.state.exteriorSummary.visibleCarbonS,
              this.$store.state.exteriorSummary.accentsS,
              // this.$store.state.exteriorSummary.accentsWheelsS
            ], {
              Canvas: Canvas,
              Image: Image,
              format: 'image/jpeg'
            }).then(side => this.$store.state.exteriorSummary.mergedS = side);

            // MERGE EXTERIOR REAR 3/4
            mergeImages([
              this.$store.state.exteriorSummary.baseR34,
              this.$store.state.exteriorSummary.bodyColourR34,
              this.$store.state.exteriorSummary.brakeDiscR34,
              this.$store.state.exteriorSummary.brakeCaliperR34,
              this.$store.state.exteriorSummary.wheelR34,
              this.$store.state.exteriorSummary.visibleCarbonR34,
              this.$store.state.exteriorSummary.accentsR34,
              // this.$store.state.exteriorSummary.accentsWheelsR34,
              this.$store.state.exteriorSummary.logoR34
            ], {
              Canvas: Canvas,
              Image: Image,
              format: 'image/jpeg'
            }).then(rear34 => this.$store.state.exteriorSummary.mergedR34 = rear34);

            // MERGE EXTERIOR REAR
            mergeImages([
              this.$store.state.exteriorSummary.baseR,
              this.$store.state.exteriorSummary.bodyColourR,
              this.$store.state.exteriorSummary.visibleCarbonR,
              this.$store.state.exteriorSummary.accentsR,
              this.$store.state.exteriorSummary.logoR
            ], {
              Canvas: Canvas,
              Image: Image,
              format: 'image/jpeg'
            }).then(rear => this.$store.state.exteriorSummary.mergedR = rear);

            // MERGE EXTERIOR TOP
            mergeImages([
              this.$store.state.exteriorSummary.baseT,
              this.$store.state.exteriorSummary.bodyColourT,
              this.$store.state.exteriorSummary.brakeCaliperT,
              this.$store.state.exteriorSummary.wheelT,
              this.$store.state.exteriorSummary.visibleCarbonT,
              this.$store.state.exteriorSummary.accentsT,
              // this.$store.state.exteriorSummary.accentsWheelsT,
              this.$store.state.exteriorSummary.logoT
            ], {
              Canvas: Canvas,
              Image: Image,
              format: 'image/jpeg'
            }).then(top => this.$store.state.exteriorSummary.mergedT = top);

            // MERGE LOGO FRONT
            mergeImages([
              this.$store.state.exteriorSummary.bodyColourLF,
              this.$store.state.exteriorSummary.visibleCarbonLF,
              this.$store.state.exteriorSummary.accentsLF,
              this.$store.state.exteriorSummary.logoLF
            ], {
              Canvas: Canvas,
              Image: Image,
              format: 'image/jpeg'
            }).then(logof => this.$store.state.exteriorSummary.mergedLF = logof);

            // MERGE LOGO REAR
            mergeImages([
              this.$store.state.exteriorSummary.bodyColourLR,
              this.$store.state.exteriorSummary.visibleCarbonLR,
              this.$store.state.exteriorSummary.accentsLR,
              this.$store.state.exteriorSummary.logoLR
            ], {
              Canvas: Canvas,
              Image: Image,
              format: 'image/jpeg'
            }).then(logor => this.$store.state.exteriorSummary.mergedLR = logor);

            // MERGE WHEEL
            mergeImages([
              this.$store.state.exteriorSummary.baseW,
              this.$store.state.exteriorSummary.bodyColourW,
              this.$store.state.exteriorSummary.brakeDiscW,
              this.$store.state.exteriorSummary.brakeCaliperW,
              this.$store.state.exteriorSummary.wheelW,
              this.$store.state.exteriorSummary.visibleCarbonW,
              this.$store.state.exteriorSummary.accentsW,
              // state.exteriorSummary.accentsWheelsW,
            ], {
              Canvas: Canvas,
              Image: Image,
              format: 'image/jpeg'
            }).then(wheel => this.$store.state.exteriorSummary.mergedW = wheel);

            // MERGE INTERIOR 1
            mergeImages([
              this.$store.state.interiorSummary.upholstery1,
              this.$store.state.interiorSummary.carbon1,
              this.$store.state.interiorSummary.stripe1,
              this.$store.state.interiorSummary.harness1,
              this.$store.state.interiorSummary.aluminum1,
              this.$store.state.interiorSummary.accents1,
            ], {
              Canvas: Canvas,
              Image: Image,
              format: 'image/jpeg'
            }).then(interior1 => this.$store.state.interiorSummary.mergedI1 = interior1);

            // MERGE INTERIOR 2
            mergeImages([
              this.$store.state.interiorSummary.upholstery2,
              this.$store.state.interiorSummary.carbon2,
              this.$store.state.interiorSummary.stripe2,
              this.$store.state.interiorSummary.harness2,
              this.$store.state.interiorSummary.aluminum2,
              this.$store.state.interiorSummary.accents2,
            ], {
              Canvas: Canvas,
              Image: Image,
              format: 'image/jpeg'
            }).then(interior2 => this.$store.state.interiorSummary.mergedI2 = interior2);

            // MERGE INTERIOR 3
            mergeImages([
              this.$store.state.interiorSummary.upholstery3,
              this.$store.state.interiorSummary.carbon3,
              this.$store.state.interiorSummary.stripe3,
              this.$store.state.interiorSummary.harness3,
              this.$store.state.interiorSummary.aluminum3,
              this.$store.state.interiorSummary.accents3,
            ], {
              Canvas: Canvas,
              Image: Image,
              format: 'image/jpeg'
            }).then(interior3 => this.$store.state.interiorSummary.mergedI3 = interior3);
            // STOP MERGING

        // START CREATING PDF
        setTimeout(() => {

          const store = this.$store.state;
          var doc = new jsPDF("l");

          doc.addImage(store.exteriorSummary.logo, "JPEG", 104.2, 86, 90, 37.3, "logo", "MEDIUM");

          doc.setFontSize(14);
          doc.setFont("Helvetica", "bold");
          doc.text("Your Personal Configuration", 117, 180);

          doc.addPage("A4", "l");
          doc.addImage(store.exteriorSummary.mergedF, "JPEG", 10, 10, 190, 190, "mergedFront", "MEDIUM");
          doc.setFont("Helvetica", "normal");
          doc.text(store.exteriorSummarySpecs[0].name, 212, 97);
          doc.setFont("Helvetica", "bold");
          doc.text(store.exteriorSummarySpecs[0].content, 212, 105);

          doc.addPage("A4", "l");
          doc.addImage(store.exteriorSummary.mergedW, "JPEG", 96.5, 10, 190, 190, "mergedWheel", "MEDIUM");
          doc.setFont("Helvetica", "normal");
          doc.text(store.exteriorSummarySpecs[7].name, 10, 87);
          doc.setFont("Helvetica", "bold");
          doc.text(store.exteriorSummarySpecs[7].content, 10, 96);
          doc.setFont("Helvetica", "normal");
          doc.text(store.exteriorSummarySpecs[8].name, 10, 107);
          doc.setFont("Helvetica", "bold");
          doc.text(store.exteriorSummarySpecs[8].content, 10, 116);

          doc.addPage("A4", "l");
          doc.addImage(store.exteriorSummary.mergedLF, "JPEG", 10, 10, 93, 93, "mergedLF", "MEDIUM");
          doc.addImage(store.exteriorSummary.mergedF34, "JPEG", 10, 105, 93, 93, "mergedF34", "MEDIUM");
          doc.addImage(store.exteriorSummary.mergedR, "JPEG", 105, 10, 93, 93, "mergedRear", "MEDIUM");
          doc.addImage(store.exteriorSummary.mergedLR, "JPEG", 105, 105, 93, 93, "mergedLR", "MEDIUM");
          doc.setFont("Helvetica", "normal");
          doc.text(store.exteriorSummarySpecs[1].name, 210, 55);
          doc.setFont("Helvetica", "bold");
          doc.text(store.exteriorSummarySpecs[1].content, 210, 64);
          doc.setFont("Helvetica", "normal");
          doc.text(store.exteriorSummarySpecs[2].name, 210, 75);
          doc.setFont("Helvetica", "bold");
          doc.text(store.exteriorSummarySpecs[2].content, 210, 84);
          doc.setFont("Helvetica", "normal");
          doc.text(store.exteriorSummarySpecs[3].name, 210, 95);
          doc.setFont("Helvetica", "bold");
          doc.text(store.exteriorSummarySpecs[3].content, 210, 104);
          doc.setFont("Helvetica", "normal");
          doc.text(store.exteriorSummarySpecs[4].name, 210, 115);
          doc.setFont("Helvetica", "bold");
          doc.text(store.exteriorSummarySpecs[4].content, 210, 124);
          doc.setFont("Helvetica", "normal");
          doc.text(store.exteriorSummarySpecs[9].name, 210, 135);
          doc.setFont("Helvetica", "bold");
          doc.text(store.exteriorSummarySpecs[9].content, 210, 144);

          doc.addPage("A4", "l");
          doc.addImage(store.exteriorSummary.mergedR34, "JPEG", 0, -45, 297, 297, "mergedRear34", "MEDIUM");

          doc.addPage("A4", "l");
          doc.addImage(store.exteriorSummary.mergedT, "JPEG", 96.5, 10, 190, 190, "mergedTop", "MEDIUM");
          doc.setFont("Helvetica", "normal");
          doc.text(store.exteriorSummarySpecs[5].name, 10, 87);
          doc.setFont("Helvetica", "bold");
          doc.text(store.exteriorSummarySpecs[5].content, 10, 96);
          doc.setFont("Helvetica", "normal");
          doc.text(store.exteriorSummarySpecs[6].name, 10, 107);
          doc.setFont("Helvetica", "bold");
          doc.text(store.exteriorSummarySpecs[6].content, 10, 116);

          doc.addPage("A4", "l");
          doc.addImage(store.exteriorSummary.mergedS, "JPEG", 0, -45, 297, 297, "mergedSide", "MEDIUM");

          doc.addPage("A4", "l");

          var textLinesConf = doc
          .splitTextToSize(store.interiorSummarySpecs[0].content, 77);
          var textLinesUph = doc
          .splitTextToSize(store.interiorSummarySpecs[3].content, 77);

          doc.addImage(store.interiorSummary.mergedI1, "JPEG", 10, 10, 190, 190, "mergedInterior1", "MEDIUM");
          doc.setFont("Helvetica", "normal");
          doc.text(store.interiorSummarySpecs[0].name, 212, 80);
          doc.setFont("Helvetica", "bold");
          doc.text(textLinesConf, 212, 89);
          doc.setFont("Helvetica", "normal");
          doc.text(store.interiorSummarySpecs[3].name, 212, 126);
          doc.setFont("Helvetica", "bold");
          doc.text(textLinesUph, 212, 135);

          doc.addPage("A4", "l");
          doc.addImage(store.interiorSummary.mergedI2, "JPEG", 96.5, 10, 190, 190, "mergedInterior2", "MEDIUM");
          doc.setFont("Helvetica", "normal");
          doc.text(store.interiorSummarySpecs[1].name, 10, 56);
          doc.setFont("Helvetica", "bold");
          doc.text(store.interiorSummarySpecs[1].content, 10, 65);
          doc.setFont("Helvetica", "normal");
          doc.text(store.interiorSummarySpecs[2].name, 10, 75);
          doc.setFont("Helvetica", "bold");
          doc.text(store.interiorSummarySpecs[2].content, 10, 84);
          doc.setFont("Helvetica", "normal");
          doc.text(store.interiorSummarySpecs[4].name, 10, 95);
          doc.setFont("Helvetica", "bold");
          doc.text(store.interiorSummarySpecs[4].content, 10, 104);
          doc.setFont("Helvetica", "normal");
          doc.text(store.interiorSummarySpecs[5].name, 10, 115);
          doc.setFont("Helvetica", "bold");
          doc.text(store.interiorSummarySpecs[5].content, 10, 124);
          doc.setFont("Helvetica", "normal");
          doc.text(store.interiorSummarySpecs[6].name, 10, 135);
          doc.setFont("Helvetica", "bold");
          doc.text(store.interiorSummarySpecs[6].content, 10, 144);

          doc.addPage("A4", "l");
          doc.addImage(store.interiorSummary.mergedI3, "JPEG", 0, -45, 297, 297, "mergedInterior3", "MEDIUM");

          doc.addPage("A4", "l");
          doc.addImage(store.exteriorSummary.logo, "JPEG", 104.2, 86, 90, 37.3, "logo", "MEDIUM");

          doc.save("Zenvo_TSRs_Your_Personal_Configuration.pdf");

          // STOP CREATING PDF

          // TURN ON SAVE BUTTON BACK
          this.show = true
          this.generating = false
        }, 5000)
      },
    }

  }
;</script>
