export default {
  // INTERIOR 1
  upholstery1: '/img/interior/upholstery/interior1/Comfort/3D/S/Stengra.jpg',
  carbon1: '/img/interior/carbon/interior1/Comfort/4x4.png',
  stripe1: '/img/interior/stripe/interior1/Comfort/Rod.png',
  harness1: '/img/interior/harness/interior1/Kulsort.png',
  aluminum1: '/img/interior/aluminum/interior1/Comfort/Sort.png',
  accents1: '/img/null.png',
  // INTERIOR 2
  upholstery2: '/img/interior/upholstery/interior2/Comfort/3D/S/Stengra.jpg',
  carbon2: '/img/interior/carbon/interior2/Comfort/4x4.png',
  stripe2: '/img/interior/stripe/interior2/Comfort/Rod.png',
  harness2: '/img/interior/harness/interior2/Kulsort.png',
  aluminum2: '/img/interior/aluminum/interior2/Comfort/Sort.png',
  accents2: '/img/null.png',
  // INTERIOR 3
  upholstery3: '/img/interior/upholstery/interior3/Comfort/3D/S/Stengra.jpg',
  carbon3: '/img/interior/carbon/interior3/Comfort/4x4.png',
  stripe3: '/img/interior/stripe/interior3/Comfort/Rod.png',
  harness3: '/img/interior/harness/interior3/Kulsort.png',
  aluminum3: '/img/interior/aluminum/interior3/Comfort/Sort.png',
  accents3: '/img/null.png',

  // UPHOLSTERY TEMP
  uType: '3D',
  uTypeName: '3D Fabric',
  uList: 'fabrics',
  uSplit: 'S',
  uSplitName: 'Stitching Only',
  uColor: 'Stengra',
  uColorName: 'Stengrå',

  // INTERIOR TEMP
  iConfiguration: 'Comfort',
  iCarbon: '4x4',
  iStripe: 'Rød',
  alType: 'Anodized',
  alColour: 'Sort',

  // MERGES
  mergedI1: '',
  mergedI2: '',
  mergedI3: '',
}
