<template>
<div class="tab-pane fade show row active" id="tab-content-ic" role="tabpanel">

  <div id="interiorConfiguration" class="row__inner">

    <div class="tile" @click="outputConfiguration(id)" :key="id" v-for="(item, id) in $store.state.interiorConfiguration">
      <div class="tile__media">
        <img class="tile__img" :class="{ tile__up: item.active }" :src="'/img/interior/configuration/thumbnails/' + item.type + '.jpg'" alt="" />
      </div>
      <div class="tile__details" :class="{ tile__active: item.active }">
        <div class="tile__title">
          {{ item.type }}
        </div>
      </div>
    </div>

  </div>

</div>
</template>

<script>
export default {
  computed: {
    interiorConfiguration() {
      return this.$store.getters.interiorConfiguration
    }
  },
  methods: {
    outputConfiguration(id) {
      const storeSum = this.$store.state.interiorSummary;
      const self = this.$store.state.interiorConfiguration[id];
      storeSum.iConfiguration = self.type;
      this.$store.dispatch('changeICarbon');
      this.$store.dispatch('changeUpholstery');
      this.$store.dispatch('changeStripe');
      this.$store.dispatch('changeAluminum');

      this.$store.state.interiorConfiguration.forEach(function(item) {
        item.active = false;
      })
      self.active = !self.active;

      this.$store.state.interiorSummarySpecs[0].content = self.type + ': ' + self.typeDesc;
      return;
    }
  }
}
</script>
