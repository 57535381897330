<template>
<div class="tab-pane fade row" id="tab-content-brakes" role="tabpanel">

  <div id="brakesRow" class="row__inner">

    <div class="tile" @click="outputBrakeOption(id)" :key="id" v-for="(item, id) in $store.state.exteriorCalipers">
      <div class="tile__media">
        <img class="tile__img" :class="{ tile__up: item.active }" :src="'/img/exterior/bodycolours/tiles/' + item.type + '-'+ item.colour + '.jpg'" alt="" />
      </div>
      <div class="tile__details" :class="{ tile__active: item.active }">
        <div class="tile__title">
          {{ item.colourName }}
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
export default {
  methods: {
    outputBrakeOption(id) {
      const storeSum = this.$store.state.exteriorSummary;
      const self = this.$store.state.exteriorCalipers[id];
      const part = self.colour + '.png'
      storeSum.brakeCaliperF34 = '/img/exterior/brakes/front34/' + part;
      storeSum.brakeCaliperR34 = '/img/exterior/brakes/rear34/' + part;
      storeSum.brakeCaliperS = '/img/exterior/brakes/side/' + part;
      storeSum.brakeCaliperT = '/img/exterior/brakes/top/' + part;
      storeSum.brakeCaliperW = '/img/exterior/brakes/wheel/' + part;

      this.$store.state.exteriorCalipers.forEach(function (item) {
        item.active = false;
      })
      self.active = !self.active;

      this.$store.state.exteriorSummarySpecs[8].content = self.colourName;
      return;
    }
  }
}
</script>
