<template>
  <div id="exteriorSide" class="bg" v-if="$store.state.views[2].show">
    <div id="base-s" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.baseS + ')' }"></div>
    <div id="bodyColour-s" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.bodyColourS + ')' }"></div>
    <div id="brakeDisc-s" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.brakeDiscS + ')' }"></div>
    <div id="brakeCaliper-s" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.brakeCaliperS + ')' }"></div>
    <div id="wheel-s" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.wheelS + ')' }"></div>
    <div id="visibleCarbon-s" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.visibleCarbonS + ')' }" alt="A"></div>
    <div id="accents-s" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.accentsS + ')' }"></div>
    <!-- <div id="accentsWheel-s" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.accentsWheelsS + ')' }"></div> -->
  </div>
</template>

<script>
  export default {

  };
</script>
