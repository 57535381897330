export default [{
    id: 'rc1',
    colour: '4No',
    rimColour: 'No tint',
    active: false
  },
  {
    id: 'rc2',
    colour: '4Rod',
    rimColour: 'Rød',
    active: false
  },
  {
    id: 'rc3',
    colour: '4Gron',
    rimColour: 'Grøn',
    active: false
  },
  {
    id: 'rc4',
    colour: '4Bla',
    rimColour: 'Blå',
    active: false
  },
  {
    id: 'rc5',
    colour: '4Lilla',
    rimColour: 'Lilla',
    active: false
  }
]
