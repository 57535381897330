export default [{
    id: 'ih1',
    ihName: 'Blå',
    ih: 'Bla',
    active: false
  },
  {
    id: 'ih2',
    ihName: 'Grøn',
    ih: 'Gron',
    active: false
  },
  {
    id: 'ih3',
    ihName: 'Gul',
    ih: 'Gul',
    active: false
  },
  {
    id: 'ih4',
    ihName: 'Rød',
    ih: 'Rod',
    active: false
  },
  {
    id: 'ih5',
    ihName: 'Kulsort',
    ih: 'Kulsort',
    active: true
  },
  {
    id: 'ih6',
    ihName: 'Lilla',
    ih: 'Lilla',
    active: false
  },
  {
    id: 'ih7',
    ihName: 'Orange',
    ih: 'Orange',
    active: false
  }
]
