<template>
  <div class="row row-specs" style="border:none;" ref="content">

    <div id="summary-text-int" class="col-12 col-sm-6">
      <h1>Your interior summary</h1>

      <div class="container text-white">
        <div class="row row-specs" :key="idx" v-for="(item, idx) in $store.state.interiorSummarySpecs">
          <div :id="item.id + '-name'" class="col-4 tbl-row-name">
            {{ item.name }}
          </div>
          <div :id="item.id" class="col-8 tbl-row-content">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>

    <div id="summary-images-int" class="col-12 col-sm-6">
      <div class="container text-white">
        <div class="carousel slide carousel-fade" data-ride="carousel">
          <div class="carousel-inner">
            <div id="sum-interior1" class="carousel-item active">
              <img id="sum-upholstery-i1" :src="$store.state.interiorSummary.upholstery1" class="sum-option-layer d-block w-100">
              <img id="sum-carbon-i1" :src="$store.state.interiorSummary.carbon1" class="sum-option-layer d-block w-100">
              <img id="sum-stripe-i1" :src="$store.state.interiorSummary.stripe1" class="sum-option-layer d-block w-100">
              <img id="sum-harness-i1" :src="$store.state.interiorSummary.harness1" class="sum-option-layer d-block w-100">
              <img id="sum-aluminum-i1" :src="$store.state.interiorSummary.aluminum1" class="sum-option-layer d-block w-100">
              <img id="sum-accents-i1" :src="$store.state.interiorSummary.accents1" class="sum-option-layer d-block w-100">
            </div>
            <div id="sum-interior2" class="carousel-item">
              <img id="sum-upholstery-i2" :src="$store.state.interiorSummary.upholstery2" class="sum-option-layer d-block w-100">
              <img id="sum-carbon-i2" :src="$store.state.interiorSummary.carbon2" class="sum-option-layer d-block w-100">
              <img id="sum-stripe-i2" :src="$store.state.interiorSummary.stripe2" class="sum-option-layer d-block w-100">
              <img id="sum-harness-i2" :src="$store.state.interiorSummary.harness2" class="sum-option-layer d-block w-100">
              <img id="sum-aluminum-i2" :src="$store.state.interiorSummary.aluminum2" class="sum-option-layer d-block w-100">
              <img id="sum-accents-i2" :src="$store.state.interiorSummary.accents2" class="sum-option-layer d-block w-100">
            </div>
            <div id="sum-interior3" class="carousel-item">
              <img id="sum-upholstery-i3" :src="$store.state.interiorSummary.upholstery3" class="sum-option-layer d-block w-100">
              <img id="sum-carbon-i3" :src="$store.state.interiorSummary.carbon3" class="sum-option-layer d-block w-100">
              <img id="sum-stripe-i3" :src="$store.state.interiorSummary.stripe3" class="sum-option-layer d-block w-100">
              <img id="sum-harness-i3" :src="$store.state.interiorSummary.harness3" class="sum-option-layer d-block w-100">
              <img id="sum-aluminum-i3" :src="$store.state.interiorSummary.aluminum3" class="sum-option-layer d-block w-100">
              <img id="sum-accents-i3" :src="$store.state.interiorSummary.accents3" class="sum-option-layer d-block w-100">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {

}
</script>
