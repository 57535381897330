<template>
  <div id="interior3" class="bg" v-if="$store.state.views[11].show">
    <div id="upholstery-i3" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.interiorSummary.upholstery3 + ')' }"></div>
    <div id="carbon-i3" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.interiorSummary.carbon3 + ')' }"></div>
    <div id="stripe-i3" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.interiorSummary.stripe3 + ')' }"></div>
    <div id="harness-i3" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.interiorSummary.harness3 + ')' }"></div>
    <div id="aluminum-i3" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.interiorSummary.aluminum3 + ')' }"></div>
    <div id="accents-i3" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.interiorSummary.accents3 + ')' }"></div>
  </div>
</template>

<script>
  export default {
  };
</script>
