<template>
  <div id="exteriorLogoF" class="bg" v-if="$store.state.views[7].show">
    <div id="bodyColour-logor" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.bodyColourLR + ')' }"></div>
    <div id="visibleCarbon-logor" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.visibleCarbonLR + ')' }"></div>
    <div id="accents-logor" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.accentsLR + ')' }"></div>
    <div id="logo-logor" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.logoLR + ')' }"></div>
  </div>
</template>

<script>
  export default {

  };
</script>
