export default [{
    id: 'ICT4',
    type: '4x4',
    typeName: '4x4',
    active: true
  },
  {
    id: 'ICTF',
    type: 'F',
    typeName: 'Fragmented',
    active: false
  }
]
