export default [
  {
    id: 'isws1',
    colourName: 'Frisk Grøn',
    colour: 'Friskgron',
    active: false
  },
  {
    id: 'isws2',
    colourName: 'Turkis',
    colour: 'Turkis',
    active: false
  },
  {
    id: 'isws3',
    colourName: 'Lilla',
    colour: 'Lilla',
    active: false
  },
  {
    id: 'isws4',
    colourName: 'Gul',
    colour: 'Gul',
    active: false
  },
  {
    id: 'isws5',
    colourName: 'Orange',
    colour: 'Orange',
    active: false
  },
  {
    id: 'isws6',
    colourName: 'Rød',
    colour: 'Rod',
    active: true
  }
]
