<template>
  <div id="exteriorTop" class="bg" v-if="$store.state.views[5].show">
    <div id="base-t" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.baseT + ')' }"></div>
    <div id="bodyColour-t" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.bodyColourT + ')' }"></div>
    <div id="brakeDisc-t" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.brakeDiscT + ')' }"></div>
    <div id="brakeCaliper-t" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.brakeCaliperT + ')' }"></div>
    <div id="wheel-t" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.wheelT + ')' }"></div>
    <div id="visibleCarbon-t" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.visibleCarbonT + ')' }" alt="A"></div>
    <div id="accents-t" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.accentsT + ')' }"></div>
    <!-- <div id="accentsWheel-t" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.accentsWheelsT + ')' }"></div> -->
    <div id="logo-t" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.logoT + ')' }"></div>
  </div>
</template>

<script>
  export default {
  };
</script>
