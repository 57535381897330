<template>
  <div id="exteriorLogoF" class="bg" v-if="$store.state.views[6].show">
    <div id="bodyColour-logof" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.bodyColourLF + ')' }"></div>
    <div id="visibleCarbon-logof" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.visibleCarbonLF + ')' }"></div>
    <div id="accents-logof" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.accentsLF + ')' }"></div>
    <div id="logo-logof" class="option-layer" :style="{ 'background-image': 'url(' + $store.state.exteriorSummary.logoLF + ')' }"></div>
  </div>
</template>

<script>
  export default {

  };
</script>
