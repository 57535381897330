<template>
<div class="tab-pane fade row" id="tab-content-ict" role="tabpanel">

  <div id="interiorCarbonType" class="row__inner">

    <div class="tile" @click="outputCarbonType(id)" :key="id" v-for="(item, id) in $store.state.interiorCarbonType">
      <div class="tile__media">
        <img class="tile__img" :class="{ tile__up: item.active }" :src="'/img/exterior/carbon/tiles/' + item.type + '.jpg'" alt="" />
      </div>
      <div class="tile__details" :class="{ tile__active: item.active }">
        <div class="tile__title">
          {{ item.typeName }}
        </div>
      </div>
    </div>

  </div>

</div>
</template>

<script>
export default {
  computed: {
    interiorCarbonType() {
      return this.$store.getters.interiorCarbonType
    }
  },
  methods: {
    outputCarbonType(id) {
      const storeSum = this.$store.state.interiorSummary;
      const self = this.$store.state.interiorCarbonType[id];
      storeSum.iCarbon = self.type;

      this.$store.dispatch('changeICarbon');

      this.$store.state.interiorCarbonType.forEach(function(item) {
        item.active = false;
      })
      self.active = !self.active;
      
      this.$store.state.interiorSummarySpecs[2].content = self.typeName;
      return;
    }
  }
}
</script>
