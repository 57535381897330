<template>
<div class="tab-pane fade row" id="tab-content-upholstery" role="tabpanel">
  <ul id="upholsteryTypeRow" class="nav sub-options">
    <li class="nav-item" :key="id" v-for="(item, id) in $store.state.interiorUTypes" @click="outputUType(id)" role="presentation">
      <a class="nav-link" :class="{ active: item.active }" data-toggle="pill" :href="'#u' + item.uType + this.$store.state.interiorSummary.uSplit" role="tab">{{ item.uTypeName }}</a>
    </li>
  </ul>
  <ul id="upholsterySplitRow" class="nav sub-options">
    <li class="nav-item" :key="id" v-for="(item, id) in $store.state.interiorUSplits" @click="outputUSplit(id)" role="presentation">
      <a class="nav-link" :class="{ active: item.active }" data-toggle="pill" :href="'#u' + this.$store.state.interiorSummary.uType + item.uSplit" role="tab">{{ item.uSplitName }}</a>
    </li>
  </ul>

  <div class="tab-content" id="accents-tabContent">

    <div :id="'#u' + this.$store.state.interiorSummary.uType + this.$store.state.interiorSummary.uSplit" class="tab-pane fade row__inner show active" role="tabpanel">

      <div :id="item.id" class="tile" @click="outputUpholstery(id)" :key="id" v-for="(item, id) in uList">
        <div class="tile__media">
          <img class="tile__img" :class="{ tile__up: item.active }" :src="'/img/interior/upholstery/thumbnails/' + this.$store.state.interiorSummary.uType + '/' + this.$store.state.interiorSummary.uSplit + '/' + item.colour + '.jpg'" alt="" />
        </div>
        <div class="tile__details" :class="{ tile__active: item.active }">
          <div class="tile__title">
            {{ item.colourName }}
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
</template>

<script>
export default {
  computed: {
    exteriorAccentsTypes() {
      return this.$store.getters.exteriorAccentsTypes
    },
    exteriorAccentsColours() {
      return this.$store.getters.exteriorAccentsColours
    },
    uList() {
      if (this.$store.state.interiorSummary.uType === '3D') {
        return this.$store.state.interiorUColors.filter(i => i.type === '3D fabric')
      } else {
        return this.$store.state.interiorUColors.filter(i => i.type === 'Leather')
      }
    }
  },
  methods: {
    outputUType(id) {
      const storeSum = this.$store.state.interiorSummary;
      const self = this.$store.state.interiorUTypes[id];
      storeSum.uType = self.uType;
      storeSum.uTypeName = self.uTypeName;
    },
    outputUSplit(id) {
      const storeSum = this.$store.state.interiorSummary;
      const self = this.$store.state.interiorUSplits[id];
      storeSum.uSplit = self.uSplit;
      storeSum.uSplitName = self.uSplitName;
      this.$store.dispatch('changeUpholstery');
      this.$store.state.interiorSummarySpecs[3].content = storeSum.uTypeName + ' ' + storeSum.uColorName + ', ' + storeSum.uSplitName;
    },
    outputUpholstery(id) {
      const storeSum = this.$store.state.interiorSummary;
      var self
      if (storeSum.uType === '3D') {
        self = this.$store.state.interiorUColors[id];
      } else {
        self = this.$store.state.interiorUColors[id + 8];
      }
      storeSum.uColor = self.colour;
      storeSum.uColorName = self.colourName;

      this.$store.dispatch('changeUpholstery');

      this.$store.state.interiorUColors.forEach(function(item) {
        item.active = false;
      })
      self.active = !self.active;

      this.$store.state.interiorSummarySpecs[3].content = storeSum.uTypeName + ' ' + storeSum.uColorName + ', ' + storeSum.uSplitName;
      return;
    }
  }
}
</script>
