<template>
<nav class="navbar navbar-expand-md navbar-dark">
  <a class="navbar-brand" href="#">
    <img src="/img/Zenvo_TSRs_Logo.svg" width="240" alt="" loading="lazy" />
  </a>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="nav nav-pills" id="switching" role="tablist">
      <li class="nav-item" role="presentation">
        <a :class="[{'active' : this.$store.state.exteriorSummary.exteriorTab}]" class="nav-link" id="tab-ext"
          @click="setExterior()">Exterior</a>
      </li>
      <li class="nav-item" role="presentation">
        <a :class="[{'active' : this.$store.state.exteriorSummary.interiorTab}]" class="nav-link" id="tab-int"
          @click="setInterior()">Interior</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" tabindex="-1" @click="restart()">
          <img src="/img/restart.svg" width="15" alt="" loading="lazy" />
        </a>
      </li>
      <li id="viewsMenu" class="nav-item dropdown">
        <a class="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown">
          <img src="/img/view.svg" width="17" alt="" loading="lazy" style="margin: 4px 0" />
        </a>
        <ul class="dropdown-menu">
          <li class="nav-item" :class="item.class" :key="id" v-for="(item, id) in $store.state.views">
            <a class="nav-link" :class="{ active: item.show }" @click="setView(id)">{{ item.name }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>

  <div class="collapse navbar-collapse justify-content-end">
    <ul class="nav list-inline">
      <!-- <li class="nav-item">
        <a class="nav-link" href="#" @click="logout()">Logout</a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" href="#" tabindex="-1" data-toggle="modal" data-target="#summary-spec">Open summary</a>
      </li>
    </ul>
  </div>
</nav>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      user: null
    };
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push('/sign-in');
        })
        .catch(error => {
          alert(error.message);
          this.$router.push('/');
        });
    },
    setExterior(){
      this.$store.state.exteriorSummary.exteriorTab = true;
      this.$store.state.exteriorSummary.interiorTab = false;
      this.$store.state.views.forEach(function(item) {
        item.show = false;
      })
      this.$store.state.views[0].show = !this.$store.state.views[0].show;
    },
    setInterior(){
      this.$store.state.exteriorSummary.exteriorTab = false;
      this.$store.state.exteriorSummary.interiorTab = true;
      this.$store.state.views.forEach(function(item) {
        item.show = false;
      })
      this.$store.state.views[9].show = !this.$store.state.views[9].show;
    },
    setView(id) {
      const self = this.$store.state.views[id];
      this.$store.state.views.forEach(function(item) {
        item.show = false;
      })
      self.show = !self.show;
      if (id < 8) {
        this.$store.state.exteriorSummary.exteriorTab = true;
        this.$store.state.exteriorSummary.interiorTab = false;
      } else {
        this.$store.state.exteriorSummary.exteriorTab = false;
        this.$store.state.exteriorSummary.interiorTab = true;
      }
    },
    restart() {
      location.reload();
    }
  }
};
</script>
