export default [{
    id: 'ra',
    type: 'Aluminum',
    rimTypeName: 'Aluminium',
    active: true
  },
  {
    id: 'rc4',
    type: 'Carbon4',
    rimTypeName: 'Carbon, 4x4',
    active: false
  },
  {
    id: 'rcf',
    type: 'CarbonF',
    rimTypeName: 'Carbon, fragmented',
    active: false
  },
]
