<template>
<div class="tab-pane fade row" id="tab-content-harness" role="tabpanel">

  <div id="interiorHarness" class="row__inner">

    <div class="tile" @click="outputHarness(id)" :key="id" v-for="(item, id) in $store.state.interiorHarness">
      <div class="tile__media">
        <img class="tile__img" :class="{ tile__up: item.active }" :src="'/img/interior/harness/thumbnails/' + item.ih + '.jpg'" alt="" />
      </div>
      <div class="tile__details" :class="{ tile__active: item.active }">
        <div class="tile__title">
          {{ item.ihName }}
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
export default {
  methods: {
    outputHarness(id) {
      const storeSum = this.$store.state.interiorSummary;
      const self = this.$store.state.interiorHarness[id];
      const part = self.ih + '.png'
      storeSum.harness1 = '/img/interior/harness/interior1/' + part;
      storeSum.harness2 = '/img/interior/harness/interior2/' + part;
      storeSum.harness3 = '/img/interior/harness/interior3/' + part;

      this.$store.state.interiorHarness.forEach(function (item) {
        item.active = false;
      })
      self.active = !self.active;

      this.$store.state.interiorSummarySpecs[1].content = self.ihName;
      return;
    }
  }
}
</script>
