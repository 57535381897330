export default [{
    id: 'ut1',
    uType: '3D',
    uTypeName: '3D fabric',
    active: true
  },
  {
    id: 'ut2',
    uType: 'Perforated',
    uTypeName: 'Perforated leather',
    active: false
  },
  {
    id: 'ut3',
    uType: 'Quilted',
    uTypeName: 'Quilted leather',
    active: false
  }
]
