<template>
<div class="tab-pane fade row" id="tab-content-accents" role="tabpanel">
  <ul id="accentsTypeRow" class="nav sub-options">
    <li class="nav-item" :key="id" v-for="(item, id) in $store.state.exteriorAccentsTypes" @click="outputAccentType(id)">
      <a class="nav-link" :class="{ active: item.active }" href="#">{{ item.accentTypeName }}</a>
    </li>
  </ul>
  <ul id="accentsColorsTypeRow" :class="[{ 'd-none': this.$store.state.exteriorSummary.aType === '' }]" class="nav sub-options">
    <li class="nav-item" :key="item.type" v-for="(item) in this.$store.state.exteriorAccentsColorsTypes" role="presentation">
      <a class="nav-link" :class="{ active: item.active }" data-toggle="pill" :href="'#accent' + item.type" role="tab">{{ item.typeName }}</a>
    </li>
  </ul>

  <div class="tab-content" id="accents-tabContent">

    <div id="accentMetallisk" class="tab-pane fade row__inner show active" :class="[{ 'disabled': this.$store.state.exteriorSummary.aType === '' }]" role="tabpanel">

      <div class="tile" @click="outputAccentColour(id)" :key="id" v-for="(item, id) in metallics">
        <div class="tile__media">
          <img class="tile__img" :class="{ tile__up: item.active }" :src="'/img/exterior/bodycolours/tiles/' + item.type + '-'+ item.colour + '.jpg'" alt="" />
        </div>
        <div class="tile__details" :class="{ tile__active: item.active }">
          <div class="tile__title">
            {{ item.colourName }}
          </div>
        </div>
      </div>

    </div>

    <div id="accentSolid" class="tab-pane fade row__inner" :class="[{ 'disabled': this.$store.state.exteriorSummary.aType === '' }]" role="tabpanel">

      <div class="tile" @click="outputAccentColour(id + 8)" :key="id + 8" v-for="(item, id) in solids">
        <div class="tile__media">
          <img class="tile__img" :class="{ tile__up: item.active }" :src="'/img/exterior/bodycolours/tiles/' + item.type + '-'+ item.colour + '.jpg'" alt="" />
        </div>
        <div class="tile__details" :class="{ tile__active: item.active }">
          <div class="tile__title">
            {{ item.colourName }}
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
</template>

<script>
export default {
  computed: {
    exteriorAccentsTypes() {
      return this.$store.getters.exteriorAccentsTypes
    },
    exteriorAccentsColours() {
      return this.$store.getters.exteriorAccentsColours
    },
    metallics: function() {
      return this.$store.state.exteriorAccentsColours.filter(i => i.type === 'Metallisk')
    },
    solids: function() {
      return this.$store.state.exteriorAccentsColours.filter(i => i.type === 'Solid')
    }
  },
  methods: {
    outputAccentType(id) {
      const storeSum = this.$store.state.exteriorSummary;
      const self = this.$store.state.exteriorAccentsTypes[id];
      storeSum.aType = self.type;

      this.$store.dispatch('changeAccents');

      this.$store.state.exteriorAccentsTypes.forEach(function(item) {
        item.active = false;
      })
      self.active = !self.active;

      storeSum.aTypeName = self.accentTypeName;
      this.$store.state.exteriorSummarySpecs[5].content = storeSum.aTypeName + ' ' + storeSum.aColourName;
      return;
    },
    outputAccentColour(id) {
      const storeSum = this.$store.state.exteriorSummary;
      const self = this.$store.state.exteriorAccentsColours[id];
      storeSum.aColour = self.colour;

      this.$store.dispatch('changeAccents');

      this.$store.state.exteriorAccentsColours.forEach(function(item) {
        item.active = false;
      })
      self.active = !self.active;

      storeSum.aColourName = self.colourName;
      this.$store.state.exteriorSummarySpecs[5].content = storeSum.aTypeName + ' ' + storeSum.aColourName;
      return;
    }
  }
}
</script>
