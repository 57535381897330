export default [{
  id: 'l1',
  typeName: '1x1',
  type: '1x1',
  active: true
},
{
  id: 'l4',
  typeName: '4x4',
  type: '4x4',
  active: false
},
{
  id: 'lf',
  typeName: 'Fragmented',
  type: 'F',
  active: false
}
]
